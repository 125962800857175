import React from "react";

import "./Banner.css";

import BannerSqaure from "./bannerSqaure/BannerSqaure";

const Banner = (props) => {
  return (
    <>
      <div className="hospitalWebBanner">
        <BannerSqaure
          data={props.data}
          openSideBarComponent={props.openSideBarComponent}
        />
      </div>
    </>
  );
};

export default Banner;
