import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DoctorListSqaure from "./DoctorListSquare/DoctorListSqaure";
import Banner from "./banner/Banner";
import DepartmentsList from "./departmentsList/DepartmentsList";
import ServicesSqaureList from "./servicesSqaure/ServicesSqaureList";
import Sidebar from "../repeat/sidebar/Sidebar";
// import ChatBot from "../chat/ChatBot";
import {
  getDivisionById,
  getDivisionMenu,
} from "../../../redux/actions-exporter";
import SquareList from "./SquareList";

import "./TrainingBase.css";

const TrainingBase = () => {
  const [openNotebookSideBar, setOpenNotebookSideBar] = useState(false);
  const [openCommanderSideBar, setOpenCommanderSideBar] = useState(false);
  const [openMarketPlaceSideBar, setOpenMarketPlaceSideBar] = useState(false);
  const [openAboutSideBar, setOpenAboutSideBar] = useState(false);
  const [openReviewsSideBar, setOpenReviewsSideBar] = useState(false);
  const [commander, setCommander] = useState();

  const params = useParams();

  const division = useSelector((state) => state.division.divisionById);
  const divisionMenu = useSelector((state) => state.division.divisionMenu);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDivisionById(params.divisionId));
    dispatch(getDivisionMenu(params.divisionId));
  }, [dispatch]); // eslint-disable-line

  const openSideBarComponent = (name) => {
    setOpenNotebookSideBar(name === "notebook");
    setOpenCommanderSideBar(name === "commander");
    setOpenMarketPlaceSideBar(name === "marketplace");
    setOpenAboutSideBar(name === "about");
    setOpenReviewsSideBar(name === "reviews");
  };

  const closeSideBarComponent = () => {
    setOpenNotebookSideBar(false);
    setOpenCommanderSideBar(false);
    setOpenMarketPlaceSideBar(false);
    setOpenAboutSideBar(false);
    setOpenReviewsSideBar(false);
  };

  const chooseCommander = (name) => setCommander(name);

  return (
    <>
      <div className="hospitalWebPages rtl division-container">
        <Container>
          <Banner data={division} openSideBarComponent={openSideBarComponent} />
          <DepartmentsList openSideBarComponent={openSideBarComponent} />
          <DoctorListSqaure
            commanders={division.commander}
            openSideBarComponent={openSideBarComponent}
            chooseCommander={chooseCommander}
          />
          <ServicesSqaureList />
          {divisionMenu.map((list) => (
            <SquareList
              key={list.id}
              title={list.title}
              menuId={list.id}
              content={list?.content || []}
              divisionId={params.divisionId}
            />
          ))}
        </Container>
        {/*<ChatBot />*/}
      </div>
      <Sidebar
        openNotebookSideBar={openNotebookSideBar}
        openCommanderSideBar={openCommanderSideBar}
        openMarketPlaceSideBar={openMarketPlaceSideBar}
        openAboutSideBar={openAboutSideBar}
        openReviewsSideBar={openReviewsSideBar}
        closeSideBarComponent={closeSideBarComponent}
        commander={commander}
      />
    </>
  );
};

export default TrainingBase;
