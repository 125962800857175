import { DivisionActionsTypes } from "./division.types";
import { axiosNoTokenInstance } from "../../axios.config";

export const getDivisions = () => (dispatch) => {
  dispatch({ type: DivisionActionsTypes.GET.GET_DIVISION_PENDING });

  axiosNoTokenInstance
    .get("/division")
    .then((res) =>
      dispatch({
        type: DivisionActionsTypes.GET.GET_DIVISION_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: DivisionActionsTypes.GET.GET_DIVISION_ERROR,
        payload: error,
      })
    );
};

export const getDivisionById = (divisionId) => (dispatch) => {
  dispatch({ type: DivisionActionsTypes.GET.GET_DIVISION_BY_ID_PENDING });

  axiosNoTokenInstance
    .get(`/division/${divisionId}`)
    .then((res) =>
      dispatch({
        type: DivisionActionsTypes.GET.GET_DIVISION_BY_ID_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: DivisionActionsTypes.GET.GET_DIVISION_BY_ID_ERROR,
        payload: error,
      })
    );
};

export const getDivisionMenu = (divisionId) => (dispatch) => {
  dispatch({ type: DivisionActionsTypes.GET.GET_DIVISION_MENU_PENDING });

  axiosNoTokenInstance
    .get(`/division/${divisionId}/menu`)
    .then((res) =>
      dispatch({
        type: DivisionActionsTypes.GET.GET_DIVISION_MENU_SUCCESS,
        payload: res.data,
      }),
      dispatch({
        type: DivisionActionsTypes.GET.SET_DIVISION_MENU_SUCCESS,
        payload: divisionId,
      })
    )
    .catch((error) =>
      dispatch({
        type: DivisionActionsTypes.GET.GET_DIVISION_MENU_ERROR,
        payload: error,
      })
    );
};
