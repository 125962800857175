export const ContentActionsTypes = {
  GET: {
    GET_CONTENT_PENDING: "GET_CONTENT_PENDING",
    GET_CONTENT_SUCCESS: "GET_CONTENT_SUCCESS",
    GET_CONTENT_ERROR: "GET_CONTENT_ERROR",

    GET_CONTENT_BY_ID_PENDING: "GET_CONTENT_BY_ID_PENDING",
    GET_CONTENT_BY_ID_SUCCESS: "GET_CONTENT_BY_ID_SUCCESS",
    GET_CONTENT_BY_ID_ERROR: "GET_CONTENT_BY_ID_ERROR",
  },
};
