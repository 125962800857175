import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import StarIcon from "@mui/icons-material/Star";
import { Swiper, SwiperSlide } from "swiper/react";

import ServiceListHeader from "../serviceList/ServiceListHeader";

import "./DoctorListSqaure.css";
import NotFoundPath from "../../../../assets/images/no-image-icon.png";

const DoctorListSqaure = (props) => {
  let slidesPerView = 10;
  if (isMobile) {
    slidesPerView = 3;
  }

  const slides = props.commanders?.map((el, index) => (
    <div className="doctorsList" key={index}>
      <Link
        to={"#"}
        onClick={() => {
          props.openSideBarComponent("commander");
          props.chooseCommander(el);
        }}
      >
        <div className="doctor_box">
          <div className="box_img">
            <img
              src={el?.image || NotFoundPath}
              alt="doctor"
              className="doctor_box_img"
            />
          </div>
          <h6>{el.title}</h6>
          <p>{el.description}</p>
          <div className="start_con">
            <span>{el.rating}</span>
            <StarIcon />
          </div>
        </div>
      </Link>
    </div>
  ));

  return (
    <>
      <div className="webDoctorList">
        <ServiceListHeader text="המפקדים שלנו" link="" btnText="לכל המפקדים" />
        <Swiper spaceBetween={0} slidesPerView={slidesPerView}>
          {slides?.length
            ? slides.map((slideContent, index) => (
                <SwiperSlide key={index} virtualIndex={index}>
                  {slideContent}
                </SwiperSlide>
              ))
            : "No commanders"}
        </Swiper>
      </div>
    </>
  );
};

export default DoctorListSqaure;
