import React, { useState } from "react";

import Video from "./Video";
import Introduction from "./introduction/Introduction";
import Services from "./Services";
import Links from "./Links";
// import ChatBot from "../chat/ChatBot";
import Sidebar from "../repeat/sidebar/Sidebar";
import Bases from "./introduction/Bases";

import "./Home.css";

const Home = () => {
  const [openMarketPlaceSideBar, setOpenMarketPlaceSideBar] = useState(false);

  const openSideBarComponent = (name, data) => {
    setOpenMarketPlaceSideBar(name === "marketplace");
  };

  const closeSideBarComponent = () => {
    setOpenMarketPlaceSideBar(false);
  };

  return (
    <div className="home page">
      <div className="row">
        <div className="col-lg-6 col-sm-12 hover nop">
          <Introduction />
        </div>
        <div className="col-lg-6 col-sm-12 hover nop">
          <Video />
        </div>
      </div>
      <div className="row">
      </div>
      <Services openSideBarComponent={openSideBarComponent} />
      <Bases />
      <Links />
      {/*<ChatBot />*/}
      <Sidebar
        openMarketPlaceSideBar={openMarketPlaceSideBar}
        setOpenMarketPlaceSideBar={setOpenMarketPlaceSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </div>
  );
};

export default Home;
