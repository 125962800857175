import React, { useEffect } from 'react'
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import { useSelector, useDispatch } from 'react-redux';

import MarketPlaceSquareList from "../marketPlaceSquare/MarketPlaceSquareList";
import { getProductCategories, getProducts } from '../../../../redux/actions-exporter';

import "./TabBtn.css";

const MarketPlaceTabs = ({ openSideBarComponent }) => {
    const productCategories = useSelector(state => state.productCategories.list)
    const products = useSelector(state => state.product.products)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!products.length) dispatch(getProducts())
        if (!productCategories.length) dispatch(getProductCategories())
    }, []) // eslint-disable-line

    return (
        <div className="tabs marketPlace__tabs">
            <TabsUnstyled defaultValue={0} scrollButtons={false}>
                <TabsListUnstyled>
                    <TabUnstyled className="tabsMenuLi">את כל</TabUnstyled>
                    {productCategories.map(category => (
                        <TabUnstyled className="tabsMenuLi" key={category.id}>{ category.title }</TabUnstyled>
                    ))}
                </TabsListUnstyled>
                <TabPanelUnstyled value={0}>
                    <MarketPlaceSquareList products={products} openSideBarComponent={openSideBarComponent} />
                </TabPanelUnstyled>
                {productCategories.map((category, i) => (
                    <TabPanelUnstyled value={i + 1} key={category.id}>
                        <MarketPlaceSquareList categoryId={category.id} openSideBarComponent={openSideBarComponent} />
                    </TabPanelUnstyled>
                ))}
            </TabsUnstyled>
        </div>
    )
}

export default MarketPlaceTabs