// import './code/generalStyle.css'
// import './code/opening.css'
// import './code/home.css'
// import './code/about.css'
// import './code/learning.css'
// import './code/exam.css'

const Notebook = () => {
  return (
    <iframe
      title="notebook"
      src="https://mador-till-prod.github.io/web-basseRules-mifkada/code/index.html"
    />
  );
};

export default Notebook;
