import axios from "axios";

export const baseURL = process.env.REACT_APP_API_URL;

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const deleteCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const axiosInstance = axios.create({
  baseURL,
  data: {},
  headers: getCookie("Authorization")
    ? { Authorization: getCookie("Authorization") }
    : {},
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      deleteCookie("Authorization");

      if (!window.location.pathname.includes("signin")) {
        window.location.assign(
          `/signin?auth-redirect=${window.location.pathname}${window.location.search}`
        );
      }
    }
    return Promise.reject(error);
  }
);

const axiosNoTokenInstance = axios.create({
  baseURL,
  data: {},
});

const axiosNoTokenDefault = axios.create({
  baseURL,
  data: {},
  headers: {
    "Accept-Language": "ru",
  },
});

const axiosForm = axios.create({
  baseURL,
  data: {},
  headers: {
    Authorization: getCookie("Authorization"),
    enctype: "multipart/form-data",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = localStorage.getItem(
      "userStorageLanguage"
    );
    return config;
  },
  (error) => Promise.reject(error)
);

axiosNoTokenInstance.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = localStorage.getItem(
      "userStorageLanguage"
    );
    return config;
  },
  (error) => Promise.reject(error)
);

export {
  getCookie,
  deleteCookie,
  axiosInstance,
  axiosNoTokenInstance,
  axiosNoTokenDefault,
  axiosForm,
};
