import React, { useState } from "react";
import Sidebar from "../../repeat/sidebar/Sidebar";

import "./Reviews.css";
import star from "../../../../assets/images/productDetails/star.png";
import review1 from "../../../../assets/images/icons/review1.png";
import review2 from "../../../../assets/images/icons/review2.png";
import bar from "../../../../assets/images/productDetails/bar.png";

const Reviews = () => {
  const [openAppointmentCommanderSideBar, setAppointmentCommanderSideBar] =
    useState(false);

  const closeSideBarComponent = () => {
    setAppointmentCommanderSideBar(false);
  };

  return (
    <>
      <div className="banner_content">
        <h1>בה"ד 7</h1>
        <h6>תקשוב</h6>
        <hr />
        <div className="review_title">
          <h3>חוות דעת</h3>
          <button className="all_reviews">לכל חוות הדעת</button>
        </div>
        <div className="productdetail_card">
          <div className="productdetail_card_div">
            <p className="productdetail_card_text">8.0</p>
            <p className="productdetail_card_subtext">מתוך 10</p>
          </div>
        </div>
        <div className="productdetail_rating">
          <div className="productdetail_star_list">
            <img className="ratingimg" src={bar} alt="bar" />

            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />

            <br />
            <img className="ratingimg" src={bar} alt="bar" />

            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />

            <br />
            <img className="ratingimg" src={bar} alt="bar" />

            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />

            <br />
            <img className="ratingimg" src={bar} alt="bar" />

            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />

            <br />
            <img className="ratingimg" src={bar} alt="bar" />

            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />
            <img alt="" src={star} className="fa fa-star checked" />

            <br />
            <p className="productdetail_rating_p">248 חוות דעת</p>
          </div>
        </div>
      </div>
      <div className="review_rating_sec">
        <div className="container">
          <div className="doctors_area">
            <div className="service_box_area">
              <div className="service_box rating_box">
                <div className="box_con review_box">
                  <div className="box_heading d-flex align-center justify-between">
                    <span>11 Feb 2022</span>
                    <h4>גיל קורסי</h4>
                  </div>
                  <span className="star">
                    5.0 <img src={star} alt="" />
                  </span>
                  <div className="price d-flex align-center justify-between">
                    <p>בסיס נקי מטופח ונח להתמצאות שמחה לשרת בו!</p>
                  </div>
                </div>
                <div className="box_round_img">
                  <img src={review1} alt="Online" />
                </div>
              </div>
              <div className="service_box rating_box">
                <div className="box_con review_box">
                  <div className="box_heading d-flex align-center justify-between">
                    <span>28 Jan 2022</span>
                    <h4>סיון ששון</h4>
                  </div>
                  <span className="star">
                    4.0 <img src={star} alt="" />
                  </span>
                  <div className="price d-flex align-center justify-between">
                    <p>בסיס חדש שכיף לשרת בו אבל חבל שאין שק"ם</p>
                  </div>
                </div>
                <div className="box_round_img">
                  <img src={review2} alt="Online" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        openAppointmentCommanderSideBar={openAppointmentCommanderSideBar}
        setAppointmentCommanderSideBar={setAppointmentCommanderSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};

export default Reviews;
