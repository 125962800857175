import { ContentActionsTypes } from "./content.types";
import { axiosNoTokenInstance } from "../../axios.config";

export const getContent = () => (dispatch) => {
  dispatch({ type: ContentActionsTypes.GET.GET_CONTENT_PENDING });

  axiosNoTokenInstance
    .get("/content")
    .then((res) =>
      dispatch({
        type: ContentActionsTypes.GET.GET_CONTENT_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: ContentActionsTypes.GET.GET_CONTENT_ERROR,
        payload: error,
      })
    );
};

export const getContentById = (contentId) => (dispatch) => {
  dispatch({ type: ContentActionsTypes.GET.GET_CONTENT_BY_ID_PENDING });

  axiosNoTokenInstance
    .get(`/content/${contentId}`)
    .then((res) =>
      dispatch({
        type: ContentActionsTypes.GET.GET_CONTENT_BY_ID_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: ContentActionsTypes.GET.GET_CONTENT_BY_ID_ERROR,
        payload: error,
      })
    );
};
