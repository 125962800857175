import Header from "../repeat/Header";
import Clothes from "./Clothes";
import Laundry from "./Laundry";
import Armoury from "./Armoury";
// import ChatBot from "../chat/ChatBot";
// import Introduction from "./introduction/Introduction";

import "./Equipment.css";

const Equipment = () => {
  return (
    <div className="military-gear page">
      <Header
        header="ציוד צבאי"
        text="צריכים ציוד? כל הפרטים כאן כדי שתוכלו לחתום ולקבל כל ציוד שתצטרכו!"
      />
      <div className="content">
        <Clothes />
        <Laundry />
        <Armoury />
        {/*<ChatBot />*/}
        {/*<Introduction />*/}
      </div>
    </div>
  );
};

export default Equipment;
