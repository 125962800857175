import { MerchantsActionTypes } from "./merchant.types";

const INITIAL_STATE = {
  isMerchantsPending: false,
  merchants: [],
  merchantsError: null,

  isMerchantByIdPending: false,
  merchantById: {},
  merchantByIdError: null,

  isMerchantByCategoryIdPending: false,
  merchantByCategoryId: {},
  merchantByCategoryIdError: null,

  isMerchantProductsPending: false,
  merchantProducts: [],
  merchantProductsError: null,
};

const MerchantsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MerchantsActionTypes.GET.GET_MERCHANT_PENDING:
      return {
        ...state,
        isMerchantsPending: true,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_SUCCESS:
      return {
        ...state,
        isMerchantsPending: false,
        merchants: action.payload,
        merchantsError: null,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_ERROR:
      return {
        ...state,
        isMerchantsPending: false,
        merchantsError: action.payload,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_PENDING:
      return {
        ...state,
        isMerchantByIdPending: true,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_SUCCESS:
      return {
        ...state,
        isMerchantByIdPending: false,
        merchantById: action.payload,
        merchantByIdError: null,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_ERROR:
      return {
        ...state,
        isMerchantByIdPending: false,
        merchantByIdError: action.payload,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_PENDING:
      return {
        ...state,
        isMerchantByCategoryIdPending: true,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_SUCCESS:
      return {
        ...state,
        isMerchantByCategoryIdPending: false,
        merchantsByCategoryId: action.payload,
        merchantByCategoryIdError: null,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_ERROR:
      return {
        ...state,
        isMerchantByCategoryIdPending: false,
        merchantByCategoryIdError: action.payload,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_PENDING:
      return {
        ...state,
        isMerchantProductsPending: true,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_SUCCESS:
      return {
        ...state,
        isMerchantProductsPending: false,
        merchantProducts: action.payload,
        merchantProductsError: null,
      };

    case MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_ERROR:
      return {
        ...state,
        isMerchantProductsPending: false,
        merchantProductsError: action.payload,
      };

    default:
      return state;
  }
};

export default MerchantsReducer;
