import React from "react";
import ServiceBanner from "./ServiceBanner";
import DoctorList from "../doctorList/DoctorList";
import "./ServiceDetails.css";
import { Link } from "react-router-dom";

const ServiceDetails = () => {
  return (
    <>
      <div className="PageDad">
        <div className="service_details_pages rtl">
          <ServiceBanner />
          <DoctorList />
          <div className="service_details_container">
            <div className="service_paragraph">
              <div className="service_details_heading">
                <h3 className="service_paragraph_price">40$</h3>
                <h3 className="service_paragraph_title">שירותים:</h3>
              </div>
              <p className="service_paragraph_p">
                חייל או חיילת המעוניינים להשלים בגרויות בזמן השירות הצבאי ו/או
                במימון צה"ל יכולים לפנות בבקשה למשקית ת"ש. לימודי בגרות לאנשי
                קבע מתקיימים בשני מחזורים בכל שנה: מחזור יוני ומחזור ינואר.
                עליהם לפנות בבקשה ללימודים עד ה -1 בפברואר כדי להצטרף למחזור
                יוני, ועד ה – 1 בספטמבר על מנת להשתתף במחזור ינואר. הבקשה נשקלת
                במחלקת תכנון כוח אדם באכ"א. אנשי קבע מעל גיל 40 יכולים לקבל
                אישור יציאה ללימודי בגרויות מטעם ראש אכ"א.
              </p>
              <p className="service_paragraph_p">
                משך הלימודים להשלמת בגרות במתמטיקה ובגרות באנגלית הוא כחודשיים.
                אם רוצים להשלים גם בגרות באזרחות הקורס מתארך בשלושה שבועות
                נוספים. במהלך הקורס אמורים התלמידים ללמוד גם בעצמם ולהכין שיעורי
                בית, וכן לעמוד בבחנים.
              </p>
              <p className="service_paragraph_p">
                חיילים בשירות קבע יכולים להשלים השכלה תיכונית ובגרויות בתיכון של
                צה"ל. תנאי הקבלה לתכנית השלמת השכלה זו הם: ציון "טוב" ומעלה
                בשלוש חוות דעת אחרונות; המלצות מפקדים עבור שיבוץ לטווח ארוך;
                עמידה במבחני כניסה בהבעה, מתמטיקה ואנגלית וקבלת ממוצע – 55 ומעל;
                מעבר שלוש בגרויות לכל היותר אך לא במקצועות אנגלית, מתמטיקה או
                עברית, או מעבר בחינה אחת לכל היותר באחד מן המקצועות הנזכרים;
                לפני המועמד/ת ארבע שנים לפחות בשירת קבע; צעיר/ה מגיל 41.
              </p>
              <p className="service_paragraph_p">
                חיילים המשרתים בצה"ל ביחידות קרביות כלוחמים או כתומכי לחימה
                יכולים לקראת מועד שחרורם להשתתף בקורסי הכנה להשלמת בגרויות.
                הקורסים ניתנים במקצועות מתמטיקה, אנגלית ואזרחות, והצבא מממן את
                הלימודים. הלימודים מתקיימים במתקן האזרחי בגבעת חביבה.
              </p>
            </div>
          </div>
          <div className="box_btn">
            <div className="container">
              <div className="btn_area">
                <Link to={"#"} className="go_btn">
                  להזמנה
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails;
