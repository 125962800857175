import { DivisionActionsTypes } from "./division.types";

const INITIAL_STATE = {
  isDivisionsPending: false,
  list: [],
  divisionsError: false,

  isDivisionByIdPending: false,
  divisionById: {},
  divisionByIdError: null,

  isDivisionMenuPending: false,
  divisionMenu: [],
  divisionMenuError: false,
  divisionId: {}
};

const DivisionReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case DivisionActionsTypes.GET.GET_DIVISION_PENDING:
      return {
        ...state,
        isDivisionPending: true,
      };

    case DivisionActionsTypes.GET.GET_DIVISION_SUCCESS:
      return {
        ...state,
        isDivisionPending: false,
        list: action.payload,
        divisionError: null,
      };

    case DivisionActionsTypes.GET.GET_DIVISION_ERROR:
      return {
        ...state,
        isDivisionPending: false,
        divisionError: action.payload,
      };

    case DivisionActionsTypes.GET.GET_DIVISION_BY_ID_PENDING:
      return {
        ...state,
        isDivisionByIdPending: true,
      };

    case DivisionActionsTypes.GET.GET_DIVISION_BY_ID_SUCCESS:
      return {
        ...state,
        isDivisionByIdPending: false,
        divisionById: action.payload,
        divisionByIdError: null,
      };

    case DivisionActionsTypes.GET.GET_DIVISION_BY_ID_ERROR:
      return {
        ...state,
        isDivisionByIdPending: false,
        divisionByIdError: action.payload,
      };

    case DivisionActionsTypes.GET.GET_DIVISION_MENU_PENDING:
      return {
        ...state,
        isDivisionMenuPending: true,
      };

    case DivisionActionsTypes.GET.GET_DIVISION_MENU_SUCCESS:
      return {
        ...state,
        isDivisionMenuPending: false,
        divisionMenu: action.payload,
        divisionMenuError: null,
      };
    case DivisionActionsTypes.GET.SET_DIVISION_MENU_SUCCESS:
      return {
        ...state,
        divisionId: action.payload
      };

    case DivisionActionsTypes.GET.GET_DIVISION_MENU_ERROR:
      return {
        ...state,
        isDivisionMenuPending: false,
        divisionMenuError: action.payload,
      };

    default:
      return state;
  }
};

export default DivisionReducer;
