import Welcome from "./Welcome";
import Bases from "./Bases";

const Introduction = () => {
  return (
    <hoverswitch className="introduction">
      <Welcome />
      {/* <div className="col-lg-5 col-sm-12 hover">
          <Welcome />
        </div>
        <div className="col-lg-7 col-sm-12 hover">
          <Bases />
        </div> */}
    </hoverswitch >
  );
};

export default Introduction;
