import React from "react";
import VandorDetailsSquare from "./VandorDetailsSquare";

const VandorDetailsSquareList = () => {
  const VandorDetailsListArr = [
    {
      vandor_details_img: "/images/vandorDetails/vandorDetails9.jpg",
      title_p_text: "ביסלי גריל",
      title_h6_text: "חבילה גדולה",
      title_span_text: "3$",
      image_content_text: "5%",
      link: "#",
    },
    {
      vandor_details_img: "/images/vandorDetails/vandorDetails2.jpg",
      title_p_text: "מחשב נייד",
      title_h6_text: "קופסה פשוטה 3",
      title_span_text: "15$",
      image_content_text: "45%",
      link: "#",
    },
    {
      vandor_details_img: "/images/vandorDetails/vandorDetails3.jpg",
      title_p_text: "חולצות",
      title_h6_text: "קופסה פשוטה 3",
      title_span_text: "15$",
      image_content_text: "25%",
      link: "#",
    },
    {
      vandor_details_img: "/images/vandorDetails/vandorDetails4.jpg",
      title_p_text: "אייפד",
      title_h6_text: "קופסה פשוטה 3",
      title_span_text: "75$",
      image_content_text: "45%",
      link: "#",
    },
    {
      vandor_details_img: "/images/vandorDetails/vandorDetails10.jpg",
      title_p_text: "רמקול",
      title_h6_text: "כוורת SX שחור",
      title_span_text: "99$",
      image_content_text: "35%",
      link: "#",
    },
    {
      vandor_details_img: "/images/vandorDetails/vandorDetails6.jpg",
      title_p_text: "פלאפון",
      title_h6_text: "קופסה פשוטה 3",
      title_span_text: "10$",
      image_content_text: "50%",
      link: "#",
    },
    {
      vandor_details_img: "/images/vandorDetails/vandorDetails11.jpg",
      title_p_text: "עוגיות אוראו",
      title_h6_text: "קופסה 175 ג'",
      title_span_text: "15$",
      image_content_text: "5%",
      link: "#",
    },
    {
      vandor_details_img: "/images/vandorDetails/vandorDetails8.jpg",
      title_p_text: "חולצות מכופתרות",
      title_h6_text: "קופסה פשוטה 3",
      title_span_text: "15$",
      image_content_text: "45%",
      link: "#",
    },
  ];

  return (
    <div className="list_box_area  d-flex justify-content-between row gx-4">
      {VandorDetailsListArr.map((el, i) => {
        return (
          <VandorDetailsSquare
            key={i}
            vandor_details_img={el.vandor_details_img}
            title_p_text={el.title_p_text}
            title_h6_text={el.title_h6_text}
            title_span_text={el.title_span_text}
            image_content_text={el.image_content_text}
            link={el.link}
          />
        );
      })}
    </div>
  );
};

export default VandorDetailsSquareList;
