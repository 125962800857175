import React, { useState } from "react";
import { Container } from "react-bootstrap";

import BlogSquareList from "../trainingBase/BlogSquare/BlogSquareList";
import DoctorListSqaure from "../trainingBase/DoctorListSquare/DoctorListSqaure";
import Banner from "./banner/Banner";
import FeaturesList from "./FeaturesList/FeaturesList";
import ServicesSqaureList from "../trainingBase/servicesSqaure/ServicesSqaureList";
import Sidebar from "../repeat/sidebar/Sidebar";
// import ChatBot from "../chat/ChatBot";

import "./Department.css";

const Department = (props) => {
  const [openAppointmentSideBar, setOpenAppointmentSideBar] = useState(false);
  const [openMarketPlaceSideBar, setOpenMarketPlaceSideBar] = useState(false);
  const [openCommanderSideBar, setOpenCommanderSideBar] = useState(false);
  const [commander, setCommander] = useState();

  const openSideBarComponent = (name) => {
    setOpenAppointmentSideBar(name === "appointment");
    setOpenMarketPlaceSideBar(name === "marketplace");
    setOpenCommanderSideBar(name === "commander");
  };

  const closeSideBarComponent = () => {
    setOpenAppointmentSideBar(false);
    setOpenMarketPlaceSideBar(false);
    setOpenCommanderSideBar(false);
  };

  const chooseCommander = (name) => {
    setCommander(name);
  };

  return (
    <>
      <div className="hospitalWebPages rtl">
        <Container>
          <Banner id={props.id} />
          <DoctorListSqaure
            openSideBarComponent={openSideBarComponent}
            chooseCommander={chooseCommander}
          />
          <FeaturesList openSideBarComponent={openSideBarComponent} />
          <ServicesSqaureList />
          <BlogSquareList />
        </Container>
        {/*<ChatBot />*/}
      </div>
      <Sidebar
        openAppointmentSideBar={openAppointmentSideBar}
        openMarketPlaceSideBar={openMarketPlaceSideBar}
        closeSideBarComponent={closeSideBarComponent}
        openCommanderSideBar={openCommanderSideBar}
        commander={commander}
      />
    </>
  );
};

export default Department;
