import SmallHeader from "../../repeat/SmallHeader";
import MealDays from "./MealDays";

const Opening = () => {
  const mealsSchedule = [
    {
      days: `ימי א' עד ה'`,
      time: [
        {
          img: "/images/s-morning.c53c11a0.svg",
          text: "בוקר",
          hours: "08:00 - 06:00",
        },
        {
          img: "/images/s-lunch.4b0ba03c.svg",
          text: "צהריים",
          hours: "14:00 - 11:30",
        },
        {
          img: "/images/s-evening.428ca55b.svg",
          text: "ערב",
          hours: " 20:00 - 18:00 ",
        },
      ],
    },
    {
      days: `שישי`,
      time: [
        {
          img: "/images/s-morning.c53c11a0.svg",
          text: "בוקר",
          hours: "08:00 - 06:30",
        },
        {
          img: "/images/s-lunch.4b0ba03c.svg",
          text: "צהריים",
          hours: "13:30 - 11:30",
        },
        {
          img: "/images/s-evening.428ca55b.svg",
          text: "ערב",
          hours: "22:00 - 18:00",
        },
      ],
    },
    {
      days: `שבת`,
      time: [
        {
          img: "/images/s-morning.c53c11a0.svg",
          text: "בוקר",
          hours: "08:00 - 06:30",
        },
        {
          img: "/images/s-lunch.4b0ba03c.svg",
          text: "צהריים",
          hours: "14:30 - 11:30",
        },
        {
          img: "/images/s-evening.428ca55b.svg",
          text: "ערב",
          hours: "19:00 - 18:00",
        },
      ],
    },
  ];

  return (
    <>
      <SmallHeader title="שעות הפתיחה" />
      <div className="opening-hours">
        {mealsSchedule.map((el, i) => {
          return <MealDays days={el.days} time={el.time} key={i} />;
        })}
      </div>
    </>
  );
};

export default Opening;
