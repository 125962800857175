import SmallHeader from "../repeat/SmallHeader";
import GallerySwiper from "../repeat/gallerySwiper/GallerySwiper";

const Clothes = () => {
  const imgArr = [
    {
      img: "/images/750_9364.9f255d34.jpg",
      className: "poolSwiperImg",
    },
    {
      img: "/images/750_9387.c54eb303.jpg",
      className: "poolSwiperImg",
    },
    {
      img: "/images/750_9388.fb01f69c.jpg",
      className: "poolSwiperImg",
    },
    {
      img: "/images/750_9391.78c5b413.jpg",
      className: "poolSwiperImg",
    },
  ];

  return (
    <div className="repository">
      <SmallHeader title="אפסנכל" />
      <div className="details-display flexible">
        <div className="row">
          <div className="col-lg-5 col-sm-12">
            <GallerySwiper
              imgArr={imgArr}
              slidesPerView={1}
              className="mySwiper mediumGallery"
            />
          </div>
          <div className="col-lg-7 col-sm-12">
            <div className="expand">
              {" "}
              המדים לא מתאימים בול? בואו להחליף ולקבל את המידות שיתאימו לכם
              <div className="col details flexible">
                <div className="flexible">
                  <div
                    className="row openingAndAddress"
                    style={{ flexDirection: "row-reverse" }}
                  >
                    <div className="col-md-6 col-sm-12">
                      <icontext className="time" icon="icon-time.svg">
                        <img
                          alt=""
                          src="/images/icon-time.13cd3768.svg"
                          className="icon"
                        />
                        <openinghours src="military-gear.json5#repositoryOpeningHours">
                          <div id="dayhours">
                            <div className="emphasis">א׳ - ה׳: </div>
                            <div>12:45 - 08:30</div>
                          </div>
                          <div id="dayhours">
                            <div id="white">i</div>
                            <div>19:25 - 13:45</div>
                          </div>
                          <div id="dayhours">
                            <div className="emphasis">ו׳: </div>
                            <div>11:45 - 08:30</div>
                          </div>
                        </openinghours>
                      </icontext>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <icontext
                        className="first-centered location"
                        icon="icon-location.svg"
                      >
                        <img
                          alt=""
                          src="/images/icon-location.b55b841e.svg"
                          className="icon"
                        />
                        המרכז המסחרי, קומה 2 ליד הספרייה
                      </icontext>
                    </div>
                  </div>
                </div>
                <div className="flexible">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <icontext
                        className="flex-end phone"
                        icon="icon-phone-black.svg"
                      >
                        <img
                          alt=""
                          src="/images/icon-phone-black.033ad4fd.svg"
                          className="icon"
                        />
                        <a className="link" href="tel: +97273-3772730">
                          <div className="text"> 073-3772730</div>
                        </a>
                        <div> או </div>
                        <a className="link" href="tel: +97273-3772729">
                          <div className="text">073-3772729</div>
                        </a>
                      </icontext>
                    </div>
                    <div className="col-md-6 col-sm-12 shirtDiv">
                      <img
                        alt=""
                        className="shirt"
                        src="/images/shirt.26360f8c.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clothes;
