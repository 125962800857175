import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getDivisions, getDivisionMenu } from "../../../../redux/API/division/division.action";
import NoImagePath from "../../../../assets/images/no-image-icon.png";

const Bases = () => {
  const [focused, setFocused] = useState(false);

  const divisions = useSelector((state) => state.division?.divisionMenu)
  const divisionId = useSelector((state) => state.division?.divisionId)
  // const divisions = useSelector((state) => state.division.list
  // .filter(item => item.id !== 2 && item.id !== 5 && item.id !== 14 && item.id !== 15));
  const dispatch = useDispatch();
  useEffect(() => {
    !divisionId === 15 && dispatch(getDivisionMenu(15));
    // if (!divisions.length) dispatch(getDivisions());
  }, [dispatch]); // eslint-disable-line

  return (
    <div
      className={focused ? "training-bases focused" : "training-bases"}
      onMouseOver={() => {
        setFocused(true);
      }}
      onMouseOut={() => {
        setFocused(false);
      }}
    >
      <div className="text">
        <div className="decoratedtitle">
          <div className="title">בה"די הקריה</div>
          <img
            alt=""
            className="title-underline"
            src="/images/title-black.07ae5174.svg"
          />
        </div>
      </div>
      <div className="links">
        {divisionId === 15 &&
          divisions?.map((division) => (
            <Link to={`/trainingbase/${division?.id}`} className="bhd link">
              <div className="imgWrapper">

                <img alt="" src={division?.image ? division?.image : NoImagePath} />
              </div>

              <div className="tag">{division?.title}</div>
              {/* {division.description} */}
            </Link>
          ))
        }
      </div>
    </div>
  );
};

export default Bases;
