import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="content">
        <div className="text till">הופק ע"י</div>
        <div className="logoDiv">
          <img
            alt=""
            className="icon till"
            src="/images/til.jpg"
          />
          <a className="b24logo" draggable="false" href="/#">
            <img
              alt=""
              className="about"
              src="/images/footer/b24logo.svg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
