import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@material-ui/core";

import Sidebar from "../../repeat/sidebar/Sidebar";

import "../cart/cart.css";
import Gift from "../../../../assets/images/cart/gift.jpg";
import Suit from "../../../../assets/images/cart/suit.jpg";
import Shoes from "../../../../assets/images/cart/shoes.jpg";
import Footlocker from "../../../../assets/images/cart/footlocker.png";

export default function Cart() {
  const [openPayNowSideBar, setOpenPayNowSideBar] = useState(false);

  const openSideBarComponent = (name) => {
    setOpenPayNowSideBar(name === "paynow");
  };

  const closeSideBarComponent = () => {
    setOpenPayNowSideBar(false);
  };

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  // const { t } = useTranslation();

  const cartdetails = [
    {
      image: Gift,
      name: "פוקס הום",
      price: '50 ש"ח',
      type: "all",
    },
    {
      image: Suit,
      name: "פייר קרדין",
      price: '500 ש"ח',
      type: "all",
    },
    {
      image: Shoes,
      name: "מגה ספורט",
      price: '250 ש"ח',
      type: "all",
    },
  ];
  return (
    <>
      {/* <Header /> */}
      <div className="container">
        <div className="cartTemplate">
          <div className="templateHeading">
            <span className="backIcon">
              <ArrowBackIcon
                onClick={() => {
                  navigate("/");
                }}
              />
            </span>
            <h1 className="templateTitle">{"עגלה"}</h1>
          </div>
          <ul className="cartItems">
            {cartdetails.length > 0 &&
              cartdetails.map((item, i) => {
                return (
                  <li className="cartProduct-Module__list" key={i}>
                    <div className="cartProduct-box">
                      <div
                        className="cartProduct-box__img"
                        style={{ backgroundImage: `url(${item.image})` }}
                      ></div>
                      <div className="cartProduct-box__info">
                        <h6 className="cartProduct-box__brand">{item.name}</h6>
                        <h5 className="cartProduct-box__name">3 {"מוצרים"}</h5>
                        <span className="cartProduct-box__price">
                          {item.price}
                        </span>
                      </div>
                    </div>
                    <div className="cartProduct-rightBlock">
                      <div className="cartProduct-box__brandBox">
                        <span className="cartProduct-brandImage">
                          <img
                            src={Footlocker}
                            alt=""
                            height={20}
                            width={20}
                            className="img-fluid"
                          />
                        </span>
                        <p className="cartProduct-brandName">{"פוט לוקר"}</p>
                        <div className="removeBtn">
                          <DeleteIcon />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
          <div className="summaryCard">
            <div className="summaryCard-tab">
              <div className="summaryCard-tabBtn">
                {"סיכום"}{" "}
                <KeyboardArrowDownIcon
                  onClick={() => {
                    if (show) {
                      setShow(false);
                    } else {
                      setShow(true);
                    }
                  }}
                />
              </div>
            </div>
            {show ? (
              <div>
                <ul className="summaryCard-List">
                  <li className="summaryCard-ListItems">
                    <p>{"subtotel"}</p>
                    <span>$1000</span>
                  </li>
                  <li className="summaryCard-ListItems">
                    <p>{"additionalProducts"}</p>
                    <span>$10</span>
                  </li>
                </ul>
                <div className="totalList">
                  <span>{"total"}</span>
                  <span>$1010</span>
                </div>
              </div>
            ) : null}
            <div className="cartBtns">
              <Button
                variant="outlined"
                // startIcon={<PercentIcon />}
                // endIcon={<NavigateNextIcon />}
                className="outlineBtn"
              >
                הוספת שובר
              </Button>
              <Button
                variant="outlined"
                onClick={() => openSideBarComponent("paynow")}
              >
                {"לתשלום"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        openPayNowSideBar={openPayNowSideBar}
        setOpenPayNowSideBar={setOpenPayNowSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
}
