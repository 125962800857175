import React, { useState } from "react";

import Sidebar from "../../repeat/sidebar/Sidebar";

import about from "./../../../../assets/images/hospital/about.jpg";
import "./About.css";

const About = () => {
  const [openAppointmentCommanderSideBar, setAppointmentCommanderSideBar] =
    useState(false);

  const closeSideBarComponent = () => {
    setAppointmentCommanderSideBar(false);
  };

  return (
    <>
      <div className="PageDad">
        <div className="doctor_pages rtl">
          <img src={about} alt="" className="commanderImg" />
          <div className="banner_content">
            <h1>אודות</h1>
          </div>

          <div className="doctors_sec doctors_details_sec">
            <div className="container">
              <div className="doctors_area">
                <div className="doctor_heading align-center justify-between">
                  <h6>כללי</h6>
                  <p className="doc_desc">הוקם בשנת 1946 בצריפין במטרה...</p>
                </div>
                <hr />

                <div className="doctor_heading align-center justify-between">
                  <h6>תחומים</h6>
                  <p className="doc_desc">רפואה, חינוך...</p>
                </div>
                <hr />

                <div className="doctor_heading align-center justify-between">
                  <h6>גודל</h6>
                  <p className="doc_desc">250 דונם</p>
                </div>
                <hr />

                <div className="doctor_heading align-center justify-between">
                  <h6>מספר חיילים</h6>
                  <p className="doc_desc">3,000</p>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        openAppointmentCommanderSideBar={openAppointmentCommanderSideBar}
        setAppointmentCommanderSideBar={setAppointmentCommanderSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};

export default About;
