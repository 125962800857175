import SmallHeader from "../repeat/SmallHeader";

const Laundry = () => {
  return (
    <div className="laundry">
      <SmallHeader title="כביסה" />
      <div className="flexible">
        <img alt="" src="/images/laundry.f7aa4442.svg" />
        <div className="col flexible">
          <div className="col flexible">
            במהלך
            <div className="emphasis"> כל ימות השבוע,</div>
            <br /> כלל החיילים יכולים להביא כל פריט כביסה
            <div className="alt-color emphasis"> למעט גרביים וחזיות</div>.
          </div>
          <div className="flex-end">
            <div className="emphasis">הכביסה הנקייה </div>
            מגיעה
            <div className="alt-color emphasis"> שבוע </div>
            לאחר שנשלחה
            <div className="alt-color emphasis"> ביום שלישי ב-08:00. </div>
          </div>
        </div>
        <img alt="" className="hangers" src="/images/hangers.87ef778c.svg" />
        <div>
          {" "}
          בתפקידי
          <div className="alt-color emphasis">
            {" "}
            רס”ר משמעת,
            <br /> דרגת רנ”ג וסגני אלופים ומעלה
          </div>{" "}
          - הכביסה מגיעה מגוהצת על קולב.
        </div>
      </div>
    </div>
  );
};

export default Laundry;
