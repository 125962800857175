import React, { useState } from "react";
import { Link } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";

import Sidebar from "../../repeat/sidebar/Sidebar";

import shekemLogoS from "../../../../assets/images/marketPlace/shekemLogoS.png";
import bills1 from "../../../../assets/images/pay/vandorDetails10.jpg";
import dottedBorder from "../../../../assets/images/pay/dotted-border.png";
import leftArrow from "../../../../assets/images/pay/leftArrow.png";
import "./Bills.css";

const Bills = () => {
  const [openPayNowSideBar, setOpenPayNowSideBar] = useState(false);

  const openSideBarComponent = (name) => {
    setOpenPayNowSideBar(name === "paynow");
  };

  const closeSideBarComponent = () => {
    setOpenPayNowSideBar(false);
  };

  return (
    <>
      <div className="PageDad">
        <div className="page">
          <div className="bills_pages rtl">
            {/* <Search icon={<StarIcon className='searchStar' style={{fontSize: '45px'}} />} /> */}

            <div className="bills_pages_area">
              <div className="bills_pages_doctor_area">
                <div className="img_and_text">
                  <img
                    alt=""
                    className="bills_pages_doctor_img"
                    src={shekemLogoS}
                  />
                  <div className="bills_pages_doctor_text">
                    <h1 className="bills_pages_doctor_text_h1">כוורת</h1>
                  </div>
                </div>
              </div>

              <div className="bills_pages_card_area">
                <div className="img_and_text">
                  <img alt="" className="bills_pages_card_img" src={bills1} />
                  <div className="bills_pages_card_text">
                    <h1 className="bills_pages_card_h1">רמקול שחור XS</h1>
                    <p className="bills_pages_card_p">24.04.2022</p>
                  </div>
                </div>
                <div className="bills_pages_card_btn_area">
                  <Link className="bills_pages_card_btn" to={"#"}>
                    99$
                  </Link>
                </div>
              </div>

              <div className="bills_pages_coupon_code_area">
                <img
                  src={dottedBorder}
                  className="dotted_border_img"
                  alt="dotted-border"
                />
                <img
                  src={leftArrow}
                  className="right_arrow"
                  alt="right-arrow"
                />
                <VerifiedIcon className="discount" />
                <input
                  type={"text"}
                  className="coupon_code"
                  placeholder="לשימוש בקופון הכנס כאן את הקוד"
                />
              </div>

              <div className="bills_pages_pay_now_area">
                <div className="bills_pages_pay_now_heading align-center justify-between">
                  <Link to={"#"} className="d-flex align-center">
                    $85
                  </Link>
                  <h3>סה"כ</h3>
                </div>

                <div className="bills_pages_box_btn">
                  <div className="bills_pages_box_btn_area">
                    <Link
                      onClick={() => openSideBarComponent("paynow")}
                      to={"#"}
                      className="bills_pages_box_go_btn"
                    >
                      שלם עכשיו
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        openPayNowSideBar={openPayNowSideBar}
        setOpenPayNowSideBar={setOpenPayNowSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};
export default Bills;
