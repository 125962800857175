import React, { useState } from "react";
import { Link } from "react-router-dom";

import Head from "./head/Head";
import ProductDetailsSquareList from "./ProductDetailsSquare/ProductDetailsSquareList";
import Sidebar from "../../repeat/sidebar/Sidebar";

import "./ProductDetails.css";
import bar from "../../../../assets/images/productDetails/bar.png";
import star from "../../../../assets/images/productDetails/star.png";

const ProductDetails = ({ product }) => {
  const [openBillsSideBar, setOpenBillsSideBar] = useState(false);
  const [openPayNowSideBar, setOpenPayNowSideBar] = useState(false);

  const openSideBarComponent = (name) => {
    setOpenBillsSideBar(name === "bills");
    setOpenPayNowSideBar(name === "paynow");
  };

  const closeSideBarComponent = () => {
    setOpenBillsSideBar(false);
    setOpenPayNowSideBar(false);
  };

  return (
    <>
      <div className="PageDad">
        <div className="productdetail_pages rtl">
          <section className="productdetail_image_sec">
            <Head text={product?.title || ""} />
            <ProductDetailsSquareList images={[product?.image]} />
          </section>

          <div className="productdetail_image_text_div">
            <p className="productdetail_p1">{product?.description || " "}</p>
            <h3 className="productdetail_p2">{product?.price || 0}$</h3>
          </div>

          <div className="productdetail_about_div">
            <h3 className="productdetail_about_div_h1">על המוצר</h3>
            <p dangerouslySetInnerHTML={{ __html: product?.content || "" }}></p>
          </div>

          <div className="productdetail_card">
            <div className="productdetail_card_div">
              <p className="productdetail_card_text">8.0</p>
              <p className="productdetail_card_subtext">מתוך 10</p>
            </div>
          </div>

          <div className="productdetail_rating">
            <h5>ביקורות</h5>
            <div className="productdetail_star_list">
              <img className="ratingimg" src={bar} alt="bar" />

              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />

              <br />
              <img className="ratingimg" src={bar} alt="bar" />

              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />

              <br />
              <img className="ratingimg" src={bar} alt="bar" />

              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />

              <br />
              <img className="ratingimg" src={bar} alt="bar" />

              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />

              <br />
              <img className="ratingimg" src={bar} alt="bar" />

              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />
              <img alt="" src={star} className="fa fa-star checked" />

              <br />
              <p className="productdetail_rating_p">248 ביקורות</p>
            </div>
          </div>

          <div className="box_btn">
            <div className="container">
              <div className="btn_area">
                <Link
                  onClick={() => openSideBarComponent("bills")}
                  to={"#"}
                  className="buy_now_btn add_to_card_btn"
                >
                  הוסף לעגלה
                </Link>
                <Link
                  onClick={() => openSideBarComponent("paynow")}
                  to={"#"}
                  className="buy_now_btn"
                >
                  שלם עכשיו
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        openPayNowSideBar={openPayNowSideBar}
        setOpenPayNowSideBar={setOpenPayNowSideBar}
        openBillsSideBar={openBillsSideBar}
        setOpenBillsSideBar={setOpenBillsSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};

export default ProductDetails;
