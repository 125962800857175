import React from "react";
// import banner from "/images/marketPlace/universty.jpg";

const ServiceBanner = () => {
  return (
    <div className="banner">
      <div className="banner_content">
        <img
          src="/images/marketPlace/universty.jpg"
          className="banner_content_img"
          alt="service"
        />
        <h1 className="">קורס השלמת בגרויות</h1>
      </div>
    </div>
  );
};

export default ServiceBanner;
