import React from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getProductsByCategoryId } from "../../../../redux/actions-exporter";
import MarketPlaceSquare from "./MarketPlaceSquare";

const MarketPlaceSquareList = ({
  products,
  categoryId,
  openSideBarComponent,
}) => {
  const dispatch = useDispatch();
  const productsByCategory = useSelector(
    (state) => state.productCategories.productsByCategoryId
  );
  const parsedProducts = products || productsByCategory || [];

  useEffect(() => {
    if (!products) dispatch(getProductsByCategoryId(categoryId));
  }, [dispatch]); // eslint-disable-line

  if (!parsedProducts.length) {
    return <Spinner />;
  }

  return (
    <div className="list_box_area d-flex justify-content-between row gx-4">
      {parsedProducts.map((product) => {
        return (
          <MarketPlaceSquare
            product={product}
            title={product?.title}
            detail={product?.description}
            price={product?.price || 0}
            openSideBarComponent={openSideBarComponent}
            key={product.id}
            image={product?.image}
          />
        );
      })}
    </div>
  );
};

export default MarketPlaceSquareList;
