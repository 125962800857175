import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import NotFoundPath from "../../../../../assets/images/no-image-icon.png";

const ProductDetailsSquareList = ({ images }) => {
  const slides = images?.map((el, index) => (
    <div className="productdetail_image_div" key={index}>
      <img src={el ? el : NotFoundPath} alt="product" />
      {/*<div className="time_dt d-flex align-content-center justify-content-center dark">*/}
      {/*  <p className="time_dt_p">{el.image_text}</p>*/}
      {/*</div>*/}
    </div>
  ));

  return (
    <>
      <Swiper spaceBetween={30}>
        {slides.map((slideContent, index) => (
          <SwiperSlide key={slideContent} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ProductDetailsSquareList;
