import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";

// import banner from "../../../../../assets/images/web/hospital/banner.jpg";

const BannerSqaure = (props) => {
  const bannerArr = [
    {
      image: "/images/hospital/tikshuv.jpg",
      title: `תקשורת`,
      detail: "פירוט של המחלקה",
      link: "#",
    },
    {
      image: "/images/hospital/afsenaut.jpg",
      title: `אפסנאות`,
      detail: "פירוט של המחלקה",
      link: "#",
    },
    {
      image: "/images/hospital/pc.jpg",
      title: `תקשוב`,
      detail: "פירוט של המחלקה",
      link: "#",
    },
  ];

  const slides = bannerArr.map((el, i) => (
    <Container>
      <Link to={"" + el.link + ""}>
        <img src={el.image} className="bannerImg" alt="banner" />
        <div className="bannerDiv">
          <h1 className="bannerDivTitle">{el.title}</h1>
          <p className="bannerDivDetail">{el.detail}</p>
        </div>
      </Link>
    </Container>
  ));

  return (
    <>
      <Swiper
        className="mySwiper"
        slidesPerView={1}
        initialSlide={`${props.id - 1}`}
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default BannerSqaure;
