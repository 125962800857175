import Btn from "./Btn";

const BtnsList = (props) => {
  const btnsListArr = [
    {
      link: "/",
      text: "בית",
      id: 15
    },
    {
      link: "/training",
      text: "עולם ההדרכה",
    },
    {
      link: "/recreation-and-fitness",
      text: "פנאי ותרבות",
    },
    {
      link: "/dining",
      text: "הסעדה",
      id: 16
    },
    {
      link: "/healthcare",
      text: "רפואה",
    },
    {
      link: "/services",
      text: "שירותים כלליים",
      id: 2
    },
    {
      link: "/equipment",
      text: "ציוד צבאי",
    },
    {
      link: "/trainingbases",
      text: 'בה"דים',
      id: 15

    },
    {
      link: "/media",
      text: "מדיה",
      id: 17
    },

    //  {
    //       link: "/transport",
    //       text: "היסעים",
    //     },
    // {
    //   link: "#",
    //   text: "המרכז המסחרי",
    //   const: "marketplace",
    // },
    // {
    //   link: "/teaching",
    //   text: "הדרכה",
    // },
  ];

  return (
    <div className="navBtnsList navs">
      {btnsListArr.map((el, i) => {
        return (
          <Btn
            id={el.id}
            link={el.link}
            text={el.text}
            key={i}
            const={el.const}
            openSideBarComponent={props.openSideBarComponent}
            openMobileMenu={props.openMobileMenu}
            setOpenMobileMenu={props.setOpenMobileMenu}
            mobile={props.mobile}
          />
        );
      })}
    </div>
  );
};

export default BtnsList;
