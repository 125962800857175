import React, { useState } from "react";
import Bases from "../home/introduction/Bases";


const TrainingBases = () => {

  return (
    <div className="home page">
      <div className="row">
        <Bases />
      </div>
    </div>
  );
};

export default TrainingBases;
