import React from "react";

import ServiceListHeader from "../serviceList/ServiceListHeader";
import ServicesSqaure from "./ServicesSqaure";

import { Container } from "react-bootstrap";
import bizua from "./../../../../assets/images/negev/bizua.jpg";
import pikuach from "./../../../../assets/images/negev/pikuach.jpg";

import tichnun from "./../../../../assets/images/negev/tichnun.jpg";
import tiful from "./../../../../assets/images/negev/tiful.jpg";

const ServicesSqaureList = () => {
  const serviceListArr = [
    {
      image: tichnun,
      title: "תכנון ",
      detail: "תכנית אדריכלית מלאה",
      review: "4.8 (1474 ביקורות)",
      // star_image: { star },
      link: "#",
    },
    {
      image: bizua,
      title: "ביצוע",
      detail: "על ידי קבלנים מורשים",
      review: "4.8 (1474 ביקורות)",
      // star_image: { star },
      link: "#",
    },
    {
      image: pikuach,
      title: "פיקוח",
      detail: "ליווי עד להשלמה",
      review: "4.8 (1474 ביקורות)",
      // star_image: { star },
      link: "#",
    },
    {
      image: tiful,
      title: "תפעול",
      detail: "כולל שירותים שונים",
      review: "4.8 (1474 ביקורות)",
      // star_image: { star },
      link: "#",
    },
  ];

  return (
    <>
      <ServiceListHeader text="השירותים שלנו" link="#" btnText="לכל השירותים" />
      <Container>
        <div className="hospitalServicesList">
          {serviceListArr.map((el, i) => {
            return (
              <ServicesSqaure
                image={el.image}
                title={el.title}
                detail={el.detail}
                review={el.review}
                // star_image={el.star_image}
                link={el.link}
                key={i}
              />
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default ServicesSqaureList;
