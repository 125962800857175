import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import NotFoundPath from "../../../../../assets/images/no-image-icon.png";

const SliderSectionSlider = ({ openSideBarComponent, products }) => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setSlides(
      products.map((el, index) => (
        <Link
          to="#" // TODO: заменить ссылки или заменить компонент Link
          key={index}
          onClick={() => openSideBarComponent("productDetails", el)}
        >
          <div className="slide_box">
            <div className="box_img">
              <img src={el.image ? el.image : NotFoundPath} alt="Nutrition" />
            </div>
            <h6>{el.title}</h6>
          </div>
        </Link>
      ))
    );
  }, [products]); // eslint-disable-line

  return (
    <>
      <Swiper spaceBetween={5} slidesPerView={3}>
        {slides.length
          ? slides.map((slideContent, index) => (
              <SwiperSlide key={index} virtualIndex={index}>
                {slideContent}
              </SwiperSlide>
            ))
          : "No products"}
      </Swiper>
    </>
  );
};

export default SliderSectionSlider;
