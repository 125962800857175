import { ProductCategoryActionsTypes } from "./productCategories.types";

const INITIAL_STATE = {
  isProductCategoriesPending: false,
  list: [],
  productCategoriesError: null,

  isProductCategoryByIdPending: false,
  productCategoryById: {},
  productCategoryByIdError: null,

  isProductsByCategoryIdPending: false,
  productsByCategoryId: [],
  productsByCategoryIdError: null,
};

const ProductCategoryReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_PENDING:
      return {
        ...state,
        isProductCategoryPending: true,
      };

    case ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        isProductCategoryPending: false,
        list: action.payload,
        productCategoryError: null,
      };

    case ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        isProductCategoryPending: false,
        productCategoryError: action.payload,
      };

    case ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_BY_ID_PENDING:
      return {
        ...state,
        isProductCategoryByIdPending: true,
      };

    case ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        isProductCategoryByIdPending: false,
        productCategoryById: action.payload,
        productsByCategoryIdError: null,
      };

    case ProductCategoryActionsTypes.GET.GET_PRODUCT_CATEGORY_BY_ID_ERROR:
      return {
        ...state,
        isProductCategoryByIdPending: false,
        productsByCategoryIdError: action.payload,
      };

    case ProductCategoryActionsTypes.GET.GET_PRODUCTS_BY_CATEGORY_ID_PENDING:
      return {
        ...state,
        isProductsByCategoryIdPending: true,
      };

    case ProductCategoryActionsTypes.GET.GET_PRODUCTS_BY_CATEGORY_ID_SUCCESS:
      return {
        ...state,
        isProductsByCategoryIdPending: false,
        productsByCategoryId: action.payload,
        productsByCategoryIdError: null,
      };

    case ProductCategoryActionsTypes.GET.GET_PRODUCTS_BY_CATEGORY_ID_ERROR:
      return {
        ...state,
        isProductsByCategoryIdPending: false,
        productsByCategoryIdError: action.payload,
      };

    default:
      return state;
  }
};

export default ProductCategoryReducer;
