import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import Banner from "./banner/Banner";
// import ChatBot from "../chat/ChatBot";
import { getContentById } from "../../../redux/actions-exporter";

import "./Blog.css";

const Blog = () => {
  const params = useParams();
  const content = useSelector((state) => state.content.contentById);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(content).length)
      dispatch(getContentById(params.contentId));
  }, [dispatch]); // eslint-disable-line

  return (
    <div className="PageDad">
      <div className="page rtl">
        <div className="postPage_1">
          {/* TODO: прикрутить текст изображения из апи */}
          <Banner image={content.image} bannerText={content.title} />
          <div className="doctor_tips">
            <h3 className="tips_title">{content.description}</h3>
            <div
              className="tips__content"
              dangerouslySetInnerHTML={{ __html: content.content }}
            />
          </div>
          {/*<ChatBot />*/}
        </div>
      </div>
    </div>
  );
};

export default Blog;
