import React, { useState, useEffect } from "react";
import { getDivisions, getDivisionMenu } from "../../../redux/API/division/division.action";
import { useDispatch, useSelector } from "react-redux";


import "./Media.css";
import Header from "../repeat/Header";
import GalleryDiv from "./GalleryDiv";
import Navigator from "../repeat/newNavigator/Navigator";

import viewNotActive from "../../../assets/images/icons/media/icon-view.57fbf428.svg";
import cultureNotActive from "../../../assets/images/icons/media/icon-culture.c5ae5454.svg";
import shopNotActive from "../../../assets/images/icons/media/icon-shop.6744a913.svg";
import gymNotActive from "../../../assets/images/icons/gym-not-active.svg";
import accommodationNotActive from "../../../assets/images/icons/media/icon-weather.82955309.svg";

import viewActive from "../../../assets/images/icons/media/icon-view-highlight.eae9c30e.svg";
import cultureActive from "../../../assets/images/icons/media/icon-culture-highlight.2da5253c.svg";
import shopActive from "../../../assets/images/icons/media/icon-shop-highlight.ee2007ce.svg";
import gymActive from "../../../assets/images/icons/gym-active.svg";
import accommodationActive from "../../../assets/images/icons/media/icon-weather-highlight.59ec05ff.svg";

const Media = () => {
  const mediaArr = useSelector((state) => state.division?.divisionMenu)
  const divisionId = useSelector((state) => state.division?.divisionId)
  const dispatch = useDispatch();
  useEffect(() => {
    !divisionId === 17 && dispatch(getDivisionMenu(17));
  }, [dispatch]); // eslint-disable-line
  // const mediaArr2 = [
  //   {

  //     header: `רחבי הקריה`,
  //     id: "view",
  //     icon: "icon-view.svg",
  //     displayname: "media.json5#view.title",
  //     src: "culture/*.jpg, library-carousell/*.jpg",
  //     titles: "media.json5#culture.imageTitles",
  //     imgArr: [
  //       {
  //         img: "/images/750_0031.c6811a09.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9558.5156d774.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_0039.ef7669db.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9572.d3e1edaa.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9582.55b89606.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9588.253d86ed.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_7577 - Copy.dea61347.jpg",
  //         className: "bigGallery",
  //       },
  //     ],
  //   },
  //   {
  //     header: `תרבות`,
  //     id: "culture",
  //     icon: "icon-view.svg",
  //     displayname: "media.json5#view.title",
  //     src: "culture/*.jpg, library-carousell/*.jpg",
  //     titles: "media.json5#culture.imageTitles",
  //     imgArr: [
  //       {
  //         img: "/images/810_7600.1a611e6a.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/810_7673.9d834118.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/810_8731.cb7700b7.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/DSC_5575.ce0b7091.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/DSC_5656.b48532f3.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_0406.70225c2a.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_1554.0987af39.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_1576.90b487a4.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_8058.0c9aed6d.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_5305.896159ed.jpg",
  //         className: "bigGallery",
  //       },
  //     ],
  //   },
  //   {
  //     header: `מרכז מסחרי`,
  //     id: "market-place-media",
  //     icon: "icon-view.svg",
  //     displayname: "media.json5#view.title",
  //     src: "culture/*.jpg, library-carousell/*.jpg",
  //     titles: "media.json5#culture.imageTitles",
  //     imgArr: [
  //       {
  //         img: "/images/TIL_2269.668252bd.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/_TIL9725.14782c09.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/_TIL9741.59b19ec1.jpg",
  //         className: "bigGallery",
  //       },
  //     ],
  //   },
  //   {
  //     header: `כושר`,
  //     id: "gym-media",
  //     icon: "icon-view.svg",
  //     displayname: "media.json5#view.title",
  //     src: "culture/*.jpg, library-carousell/*.jpg",
  //     titles: "media.json5#culture.imageTitles",
  //     imgArr: [
  //       {
  //         img: "/images/750_9589.f7eb0803.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9610.042f0f6f.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9620.51d80964.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9621.8722fef0.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9628.15ba37a2.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9629.58dfc395.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9631.95f7c090.jpg",
  //         className: "bigGallery",
  //       },

  //       {
  //         img: "/images/750_9642.9ae29aeb.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/810_8460.51e7a09f.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/_TIL6996.e6c05f33.jpg",
  //         className: "bigGallery",
  //       },
  //     ],
  //   },
  //   {
  //     header: `מגורים`,
  //     id: "accommodation",
  //     icon: "icon-view.svg",
  //     displayname: "media.json5#view.title",
  //     src: "culture/*.jpg, library-carousell/*.jpg",
  //     titles: "media.json5#culture.imageTitles",
  //     imgArr: [
  //       {
  //         img: "/images/750_9767.3013ab9d.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/750_9787.63e50bef.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/DSC_0829.93c251a0.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/DSC_0884.21655e27.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_2368.3d32508d.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_6516.ad321ed9.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/TIL_6534.e16e0474.jpg",
  //         className: "bigGallery",
  //       },

  //       {
  //         img: "/images/TIL_7564.8e20a556.jpg",
  //         className: "bigGallery",
  //       },
  //       {
  //         img: "/images/_TIL7507.2f703120.jpg",
  //         className: "bigGallery",
  //       },
  //     ],
  //   },
  // ];

  const navigatorArr = [
    {
      to: "24",
      hoverText: "רחבי הקריה",
      imgActive: viewActive,
      imgNotActive: viewNotActive,
    },
    {
      to: "25",
      hoverText: "תרבות",
      imgActive: cultureActive,
      imgNotActive: cultureNotActive,
    },
    {
      to: "26",
      hoverText: "מרכז מסחרי",
      imgActive: shopActive,
      imgNotActive: shopNotActive,
    },
    {
      to: "27",
      hoverText: "כושר",
      imgActive: gymActive,
      imgNotActive: gymNotActive,
    },
    {
      to: "28",
      hoverText: "מגורים",
      imgActive: accommodationActive,
      imgNotActive: accommodationNotActive,
    },
  ];

  return (
    <div className="media page">
      <Header
        header="מדיה"
        text="תמונות של הקריה, נופים, מתקנים, אטרקציות וכו’."
      />
      <navigatable>
        <div className="content">
          <Navigator navigatorArr={navigatorArr} />
          <div className="textArea">
            {divisionId === 17 &&
              mediaArr.map((el, i) => {
                return (
                  <GalleryDiv
                    header={el.title}
                    id={el.id}
                    icon={"icon-view.svg"}
                    displayname={el.displayname}
                    // src={el.src}
                    // titles={el.titles}
                    imgArr={el.content?.map(a => a.image)}
                    key={i}
                  />
                );
              })}
          </div>
        </div>
      </navigatable>
    </div>
  );
};

export default Media;
