import SmallHeader from "../repeat/SmallHeader";

const Armoury = () => {
  return (
    <div className="armoury">
      <SmallHeader title="נשקייה" />
      <div className="col flexible">
        <div className="flexible">
          <icontext className="expand" icon="icon-time.svg">
            <img alt="" src="/images/icon-time.13cd3768.svg" className="icon" />
            סגל יקר, אל תלכו עד לנשקייה סתם! בדקו מהן שעות הפתיחה...
          </icontext>
          <div className="expand">
            ניפוק נשק לסגל
            <div className="alt-color emphasis"> ליותר מ-15 איש </div>
            דורש תיאום מראש.
          </div>
          <icontext className="expand if phone" icon="icon-phone-black.svg">
            <img
              alt=""
              src="/images/icon-phone-black.033ad4fd.svg"
              className="icon"
            />
            לפרטים ותיאומים:
            <a className="link" href="tel: 97273-3772384">
              <div className="text">073-3772384</div>
            </a>
          </icontext>
        </div>
        <div className="flexible schedule">
          <div className="expand flexible">
            <openinghours
              className="no-start-margin"
              src="military-gear.json5#armoury.openingHours"
            >
              <div>
                <div className="emphasis">א׳: </div>
                <div>11:45 - 09:30</div>
              </div>
              <div>
                <div className="emphasis">ב׳ - ג׳: </div>
                <div>10:00 - 08:00</div>
              </div>
              <div>
                <div className="emphasis">ד׳: </div>
                <div>10:00 - 08:00</div>
              </div>
              <div>
                <div className="emphasis">ה׳: </div>
                <div>11:45 - 08:00</div>
              </div>
            </openinghours>
            <div className="break">הפסקה</div>
            <openinghours
              type="hours"
              src="military-gear.json5#armoury.afterBreaks"
            >
              <div>
                <div>15:00 - 13:00</div>
              </div>
              <div>
                <div>14:30 - 13:00</div>
              </div>
              <div>
                <div>16:30 - 14:30</div>
              </div>
              <div>
                <div>15:00 - 13:00</div>
              </div>
            </openinghours>
          </div>
          <div className="expand">
            <div className="alt-color emphasis">פלוגת הגנה </div>
            יקבלו שירות
            <b> בשלישי </b>
            בין השעות
            <b> 11:00 - 11:45 </b>, חריגים עד השעה 14:00.
          </div>
          <div className="expand flexible">
            <img alt="" className="gun" src="/images/gun.41f89caf.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Armoury;
