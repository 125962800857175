import React from "react";

import "./Banner.css";

const Banner = ({ image, bannerText }) => {
  return (
    <div className="gynecologybanner">
      <img alt="" className="bannerBG" src={image} />
      <div className="bannerText">
        <div className="bannerTextH">{bannerText}</div>
      </div>
    </div>
  );
};

export default Banner;
