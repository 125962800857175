import React from "react";

import ProductsSquare from "./ProductsSquare";

import NotFoundPath from "../../../../../assets/images/no-image-icon.png";

const ProductsSquareList = ({ products, openSideBarComponent }) => {
  return (
    <div className="list_box_area d-flex justify-content-between row gx-4">
      {products?.map((el, i) => {
        return (
          <ProductsSquare
            product={el}
            key={i}
            product_img={el?.image || NotFoundPath}
            title={el.title}
            detail={el.description || ""}
            price={el.price || 0}
            link={el?.link || ""}
            product_img_key={el.id}
            openSideBarComponent={openSideBarComponent}
          />
        );
      })}
    </div>
  );
};

export default ProductsSquareList;
