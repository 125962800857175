import SmallHeader from "../../repeat/SmallHeader";
import TrainingCard from "../trainingCard/TrainigCard";

function TrainingCardList(props) {
  return (
    <div className="trainingCards" id={props.trainingObj.id}>
      <SmallHeader title={props.trainingObj.baseName} />
      <div className="trainingCardList">
        {props.trainingObj.bahadArr.map((el, i) => {
          return (
            <TrainingCard
              key={i}
              title={el.title}
              year={el.year}
              type={el.type}
              description={el.description}
              img={el.img}
              link={el.link}
              isItOpenPopup={el.isItOpenPopup}
              openSideBarComponent={props.openSideBarComponent}
              chooseGame={props.chooseGame}
            />
          );
        })}
      </div>
    </div>
  );
}

export default TrainingCardList;
