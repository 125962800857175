export const DivisionActionsTypes = {
  GET: {
    GET_DIVISION_PENDING: "GET_DIVISION_PENDING",
    GET_DIVISION_SUCCESS: "GET_DIVISION_SUCCESS",
    GET_DIVISION_ERROR: "GET_DIVISION_ERROR",

    GET_DIVISION_BY_ID_PENDING: "GET_DIVISION_BY_ID_PENDING",
    GET_DIVISION_BY_ID_SUCCESS: "GET_DIVISION_BY_ID_SUCCESS",
    GET_DIVISION_BY_ID_ERROR: "GET_DIVISION_BY_ID_ERROR",

    GET_DIVISION_MENU_PENDING: "GET_DIVISION_MENU_PENDING",
    GET_DIVISION_MENU_SUCCESS: "GET_DIVISION_MENU_SUCCESS",
    SET_DIVISION_MENU_SUCCESS: "SET_DIVISION_MENU_SUCCESS",
    GET_DIVISION_MENU_ERROR: "GET_DIVISION_MENU_ERROR",
  },
};
