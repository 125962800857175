import React from "react";

import "../../blogs/banner/Banner.css";
import banner from "../../../../assets/images/blogs/news2.jpg";

const Banner = () => {
  return (
    <div className="gynecologybanner">
      <img alt="" className="bannerBG" src={banner} />
      <div className="bannerText">
        <div className="bannerTextH">טקס ראש השנה השנתי</div>
      </div>
    </div>
  );
};

export default Banner;
