import Camera from "@mui/icons-material/CameraAlt";

const OpenFault = () => {
  return (
    <div className="openFault">
      <div>פתיחת תקלה</div>
      <Camera />
    </div>
  );
};

export default OpenFault;
