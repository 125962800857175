import Card from "react-bootstrap/Card";

import "./TrainingCard.css";

function TrainingCard({
  title,
  img,
  description,
  link,
  openSideBarComponent,
  chooseGame,
}) {
  let openBySideBar = true;
  // if (isItOpenPopup) {
  //   popupLink = link
  //   openBySideBar = false
  // }

  return (
    // <a href={popupLink}>
    <Card
      onClick={() => {
        if (openBySideBar) {
          openSideBarComponent("game");
          chooseGame(link);
        }
      }}
      className="trainingCard"
      style={{ width: "13rem" }}
    >
      <Card.Img variant="top" src={img} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
    // </a>
  );
}

export default TrainingCard;
