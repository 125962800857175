import "../hospitalStay/History.css";
import OpenSquare from "../hospitalStay/OpenSquare.js";
import OpenFault from "../openFault/OpenFault";

const Sewerage = () => {
  const cleanings = [
    {
      img: "/images/services/sewerage.jpg",
      date: "11.7.2022",
      person: "בוצע על ידי חנה לוי",
      link: "#",
    },
    {
      img: "/images/services/sewerage.jpg",
      date: "6.7.2022",
      person: "בוצע על ידי יוסי כהן",
      link: "#",
    },
    {
      img: "/images/services/sewerage.jpg",
      date: "1.7.2022",
      person: "בוצע על ידי חנה לוי",
      link: "#",
    },
  ];

  return (
    <div className="PageDad">
      <div className="page">
        <div className="patientPage">
          <div className="patientImgDiv">
            <img
              className="patientImgPhoto"
              src="/images/services/sewerage.jpg"
              alt="aa"
            />
            <div className="patientImgText">
              <h1 className="patientImgH">ביוב</h1>
              <div className="patientImgP"></div>
            </div>
          </div>
          <OpenFault />
          <div className="patientTextDiv">
            <h2 className="patientTextH">היסטוריית תיקונים</h2>
            <div className="patientTextPDiv">
              <div className="patientTextPText">תיקון אחרון</div>
              <div className="patientTextPDate">07.11.2022</div>
            </div>
          </div>
          <div className="patientSquaresDiv">
            {cleanings.map((el, i) => {
              return (
                <OpenSquare
                  img={el.img}
                  date={el.date}
                  person={el.person}
                  link={el.link}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sewerage;
