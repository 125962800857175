import React from "react";
import { Button } from "@mui/material";

import kaveret from "../../../../../assets/images/vandorDetails/banner.png";

import "./Banner.css";

const Banner = () => {
  return (
    <div className="vandor_details_banner_div">
      <div className="vandor_details_banner_img">
        <div className="vandor_details_banner_content">
          <img src={kaveret} className="banner_content_gift" alt="gift" />
          <div className="banner_content_div">
            <span className="banner_content_span_1">כוורת</span>
            <span className="banner_content_span_2">חנות נוחות</span>
          </div>
          <Button className="banner_content_btn">עקוב</Button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
