// import App from './tkbel-master/web/src/App.tsx'
import styles from "./tkbel.module.css";

const Tkbel = () => {
  return (
    <>
      {/* <div className="tkbel"> */}
      {/* <App /> */}
      <iframe
        title="tkbel"
        className={styles.iframe}
        src="https://web.tkbel.io/"
      />
      {/* </div> */}
    </>
  );
};

export default Tkbel;
