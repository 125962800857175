import React from "react";
import { Link } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import NoImagePath from "../../../../assets/images/no-image-icon.png";

const MarketPlaceSquare = (props) => {
  return (
    <>
      <div className="list_box col-6 pb-5">
        <Link
          to={"" + props.link + ""}
          onClick={() => {
            props.openSideBarComponent("productDetails", props.product);
          }}
        >
          <div className="box_img">
            <img src={props.image ? props.image : NoImagePath} alt="service" />
            {/* {props.market_place_img_key === 1 ? (
              <>
                <div className="time_dt d-flex justify-content-center">
                  <p className="off15">
                    <strong>35% הנחה</strong>
                    <span>03:25:41</span>
                  </p>
                </div>
              </>
            ) : props.market_place_img_key === 2 ? (
              <>
                <div className="time_dt d-flex align-content-center justify-content-center dark">
                  <p>
                    <strong>45%</strong>
                  </p>
                </div>
              </>
            ) : props.market_place_img_key === 3 ? (
              <></>
            ) : props.market_place_img_key === 4 ? (
              <></>
            ) : (
              ""
            )} */}
          </div>
        </Link>
        <div className="box_detail d-flex justify-content-between">
          <div className="product_title">
            <p>{props.title}</p>
            <h6>{props.detail}</h6>
          </div>
          <span>{props.price}$</span>
        </div>
        <Link to={"#"} className="wish_list_icon">
          <FavoriteBorderIcon />
        </Link>
      </div>
    </>
  );
};

export default MarketPlaceSquare;
