import React, { useState } from "react";

import "./Services.css";

import Header from "../repeat/Header";
import Barbershop from "./Barbershop";
import Faults from "./Faults";
import Religion from "./Religion";
import Sidebar from "./../repeat/sidebar/Sidebar";
import Transport from "../transport/Transport"
import scissorsNotActive from "../../../assets/images/icons/services/scissors.4f74a556.svg";
import ticketsNotActive from "../../../assets/images/icons/services/tickets.a7b87c29.svg";
import religionNotActive from "../../../assets/images/icons/services/religion.9cc2484c.svg";

import transportNotActive from "../../../assets/images/icons/home/transport-not-active.svg";
import transportActive from "../../../assets/images/icons/home/transport-active.svg";
import scissorsActive from "../../../assets/images/icons/services/scissors-highlight.bd894641.svg";
import ticketsActive from "../../../assets/images/icons/services/tickets-highlight.8136b300.svg";
import religionActive from "../../../assets/images/icons/services/religion-highlight.69b9d922.svg";

import Navigator from "../repeat/newNavigator/Navigator";

const Services = () => {
  const [openAppointmentSideBar, setOpenAppointmentSideBar] = useState(false);
  const [openHospitalSideBar, setOpenHospitalSideBar] = useState(false);

  const openSideBarComponent = (name) => {
    setOpenAppointmentSideBar(name === "appointment");
    setOpenHospitalSideBar(name === "hospitalstay");
  };

  const closeSideBarComponent = () => {
    setOpenAppointmentSideBar(false);
    setOpenHospitalSideBar(false);
  };

  const navigatorArr = [
    {
      to: "barber-shop",
      hoverText: "מספרה ",
      imgActive: scissorsActive,
      imgNotActive: scissorsNotActive,
    },
    {
      to: "faults",
      hoverText: "תקלות",
      imgActive: ticketsActive,
      imgNotActive: ticketsNotActive,
    },
    {
      to: "religion",
      hoverText: "רבנות",
      imgActive: religionActive,
      imgNotActive: religionNotActive,
    },
    {
      to: "transport",
      hoverText: "היסעים",
      imgActive: transportActive,
      imgNotActive: transportNotActive,
    },
  ];

  return (
    <div className="services page">
      <Header
        header="שירותים כלליים"
        text="משהו התקלקל? צריכים להסתפר? אולי להתייעץ עם הרב? כל הפרטים כאן!"
      />
      <navigatable>
        <div className="content">
          <Navigator navigatorArr={navigatorArr} />
          <div className="textArea">
            <Barbershop openSideBarComponent={openSideBarComponent} />
            <Faults openSideBarComponent={openSideBarComponent} />
            <Religion />
            <Transport />
          </div>
        </div>
      </navigatable>
      <Sidebar
        openAppointmentSideBar={openAppointmentSideBar}
        setOpenAppointmentSideBar={setOpenAppointmentSideBar}
        openHospitalSideBar={openHospitalSideBar}
        setOpenHospitalSideBar={setOpenHospitalSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </div>
  );
};

export default Services;
