import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Link } from "react-router-dom";

import TabBtn from "../tabsMenu/TabBtn";
import ProductsSquareList from "./ProductsSquare/ProductsSquareList";
// import BestDealsSquareList from "./BestDealsSquare/BestDealsSquareList";
import Filter from "../filter/Filter";
import Sidebar from "../../repeat/sidebar/Sidebar";
import { getMerchantProducts } from "../../../../redux/API/merchant/merchant.action";

import "./Products.css";

const Products = ({ merchantId }) => {
  const [showfilter, setShowfilter] = useState(false);
  const [showdrp, setShowdrp] = useState(false);
  const [showshortdrp, setShowshortdrp] = useState(false);
  const [payload, setPayload] = useState(false);

  const [openProductDetailsSideBar, setOpenProductDetailsSideBar] =
    useState(false);

  const openSideBarComponent = (name, payload) => {
    setOpenProductDetailsSideBar(name === "productDetails");
    setPayload(payload);
  };

  const closeSideBarComponent = () => {
    setOpenProductDetailsSideBar(false);
    setPayload(false);
  };
  const merchantProducts = useSelector(
    (state) => state.merchants.merchantProducts
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!merchantProducts.length) dispatch(getMerchantProducts(merchantId));
  }, [dispatch]); // eslint-disable-line

  const messagesEndRef = useRef();
  const messagesEndRef1 = useRef();

  const scrollToBottom = () => {
    messagesEndRef1.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const tabsBtnsArr = [
    {
      text: "הכל",
    },
    {
      text: "משלוחים",
    },
    {
      text: "תיקוני פלאפונים",
    },
    {
      text: "כביסה",
    },
  ];

  const active = (e) => {
    const choosen = e.target.parentElement;
    const options = document.querySelectorAll(".tabsMenuLi");
    for (let i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }
    choosen.classList.add("active");
  };

  return (
    <>
      <div className="PageDad">
        <div className="products_pages rtl">
          <section className="search_sec">
            <div className="search_area">
              <Link
                to={"#"}
                className="filter_icon"
                onClick={() => {
                  setShowfilter(!showfilter);
                  if (showfilter) {
                    scrollToTop();
                  } else {
                    scrollToBottom();
                  }
                }}
              >
                <FilterListIcon />
              </Link>
              <div className="search_form">
                <button type="submit" className="btn_sub">
                  <SearchIcon className="SearchImg" />
                </button>
                <input
                  type="Search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חיפוש"
                />
              </div>
            </div>
          </section>

          <Filter
            showfilter={showfilter}
            setShowfilter={setShowfilter}
            scrollToTop={scrollToTop}
            scrollToBottom={scrollToBottom}
            showdrp={showdrp}
            setShowdrp={setShowdrp}
            showshortdrp={showshortdrp}
            setShowshortdrp={setShowshortdrp}
            messagesEndRef1={messagesEndRef1}
          />

          <section className="service_list_sec">
            <div className="tabs">
              <ul id="tabs-nav" className="list-unstyled">
                {tabsBtnsArr.map((el, i) => {
                  return (
                    <TabBtn active={active} text={el.text} key={i} i={i} />
                  );
                })}
              </ul>
              {/* <!-- END tabs-nav --> */}
              <div id="tabs-content">
                <div id="tab1" className="tab-content">
                  <ProductsSquareList
                    products={merchantProducts}
                    openSideBarComponent={openSideBarComponent}
                  />
                </div>
              </div>
              {/* <!-- END tabs-content --> */}
            </div>
          </section>

          {/*<section className="service_list_sec best_deals_sec">*/}
          {/*  <h3>דילים פופלרים</h3>*/}
          {/*  <div className="tabs">*/}
          {/*    <div id="tabs-content">*/}
          {/*      <div id="tab1" className="tab-content">*/}
          {/*        <BestDealsSquareList*/}
          {/*          openSideBarComponent={openSideBarComponent}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}
        </div>
      </div>
      <Sidebar
        payload={payload}
        openProductDetailsSideBar={openProductDetailsSideBar}
        setOpenProductDetailsSideBar={setOpenProductDetailsSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};

export default Products;
