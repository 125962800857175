import React from "react";
import AppointmentCommander from "../../trainingBase/appointmentCommander/AppointmentCommander";
import Appointment from "../../services/appointment/Appointment";
import Notebook from "../../notebook/Notebook";
import Commander from "../../trainingBase/DoctorListSquare/Commander.js";
import About from "../../trainingBase/about/About";
import Reviews from "../../trainingBase/reviews/Reviews";
import HospitalStay from "../../services/hospitalStay/HospitalStay";
import Clean from "../../services/clean/Clean";
import Ac from "../../services/ac/Ac";
import Sewerage from "../../services/sewerage/Sewerage";
import Water from "../../services/water/Water";
import Electricity from "../../services/electricity/Electricity";
import "./Sidebar.css";
import Game from "../../training/game/Game";
import CloseIcon from "@mui/icons-material/Close";
import MarketPlace from "../../marketPlace/MarketPlace";
import Cart from "../../marketPlace/cart/Cart";
import Stores from "../../marketPlace/stores/Stores";
import Bills from "../../pay/bills/Bills";
import AddCard from "../../pay/addCard/AddCard";
import PayNow from "../../pay/payNow/PayNow";
import PaymentSuccess from "../../pay/paymentSuccess/PaymentSuccess";
import VandorDetails from "../../marketPlace/vandorDetails/VandorDetails";
import ServiceDetails from "../../marketPlace/serviceDetails/ServiceDetails";
import Products from "../../marketPlace/products/Products";
import ProductDetails from "../../marketPlace/productDetails/ProductDetails";

const Sidebar = (props) => {
  const checkPropSidebar =
    props.openCommanderSideBar ||
    props.openNotebookSideBar ||
    props.openGameSideBar ||
    props.openAppointmentSideBar ||
    props.openAppointmentCommanderSideBar ||
    props.openHospitalSideBar ||
    props.openCleanSideBar ||
    props.openElectricitySideBar ||
    props.openSewerageSideBar ||
    props.openWaterSideBar ||
    props.openAcSideBar ||
    props.openMarketPlaceSideBar ||
    props.openStoresSideBar ||
    props.openCartSideBar ||
    props.openBillsSideBar ||
    props.openPayNowSideBar ||
    props.openAddCardSideBar ||
    props.openPaymentSuccessSideBar ||
    props.openVandorDetailsSideBar ||
    props.openServiceDetailsSideBar ||
    props.openProductsSideBar ||
    props.openProductDetailsSideBar ||
    props.openAboutSideBar ||
    props.openReviewsSideBar;

  return (
    <div
      className={
        checkPropSidebar
          ? "customizer border-left-blue-grey border-left-lighten-4 open"
          : "customizer border-left-blue-grey border-left-lighten-4 d-none d-md-block"
      }
    >
      <div className="customizer-content pt-2">
        <div className="closeBtnHeader">
          <div
            className="closeBtnDiv"
            onClick={() => props.closeSideBarComponent(false)}
          >
            <CloseIcon />
          </div>
        </div>
        {props.openAppointmentSideBar === true ? (
          <Appointment />
        ) : props.openAppointmentCommanderSideBar === true ? (
          <AppointmentCommander />
        ) : props.openNotebookSideBar === true ? (
          <Notebook />
        ) : props.openGameSideBar === true ? (
          <Game game={props.game} />
        ) : props.openCommanderSideBar === true ? (
          <Commander
            commander={props.commander}
            openSideBarComponent={props.openSideBarComponent}
          />
        ) : props.openHospitalSideBar === true ? (
          <HospitalStay />
        ) : props.openCleanSideBar === true ? (
          <Clean />
        ) : props.openElectricitySideBar === true ? (
          <Electricity />
        ) : props.openSewerageSideBar === true ? (
          <Sewerage />
        ) : props.openWaterSideBar === true ? (
          <Water />
        ) : props.openAcSideBar === true ? (
          <Ac />
        ) : props.openVandorDetailsSideBar === true ? (
          <VandorDetails />
        ) : props.openServiceDetailsSideBar === true ? (
          <ServiceDetails />
        ) : props.openMarketPlaceSideBar === true ? (
          <MarketPlace />
        ) : props.openStoresSideBar === true ? (
          <Stores />
        ) : props.openCartSideBar === true ? (
          <Cart />
        ) : props.openBillsSideBar === true ? (
          <Bills />
        ) : props.openPayNowSideBar === true ? (
          <PayNow />
        ) : props.openAddCardSideBar === true ? (
          <AddCard />
        ) : props.openPaymentSuccessSideBar === true ? (
          <PaymentSuccess />
        ) : props.openProductsSideBar === true ? (
          <Products merchantId={props.payload} />
        ) : props.openProductDetailsSideBar === true ? (
          <ProductDetails product={props.payload} />
        ) : props.openAboutSideBar === true ? (
          <About />
        ) : props.openReviewsSideBar === true ? (
          <Reviews />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default Sidebar;
