import React from "react";
import "./Banner.css";
import negev from "./../../../../assets/images/negev/negev.jpg";

const Banner = (props) => {
  return (
    <>
      <div className="hospitalWebBanner">
        <div className="mySwiper">
          <img src={negev} className="bannerImg" alt="banner" />
          <div className="bannerDiv">
            <h1 className="bannerDivTitle">מבט לנגב</h1>
            <p className="bannerDivDetail">מנרב | אלקטרה | בינת | נוי</p>
            <div className="bannerDivDetailDiv"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
