import React from "react";

const TabBtn = ({ active, content }) => {
  let className = "tabsMenuLi";

  return (
    <li className={className} onClick={active}>
      {/* {text} */}
    </li>
  );
};

export default TabBtn;
