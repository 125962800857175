import React from "react";

import Banner from "./banner/Banner";
// import ChatBot from "../chat/ChatBot";

import "../blogs/Blog.css";

const News = () => {
  return (
    <div className="PageDad">
      <div className="page rtl">
        <div className="postPage_1">
          <Banner />
          <div className="doctor_tips">
            <h3 className="tips_title">הטקס ייערך ביום א' 18.09.22</h3>
            <h6>
              הטקס יערך בנוכחותו של מפקד קריית ההדרכה אל"מ חי כפיר מגנאזי, חלה
              חובת נוכחות על כלל החיילים המשרתים בבסיס ללא יוצא מהכלל.
            </h6>
          </div>
          {/*<ChatBot />*/}
        </div>
      </div>
    </div>
  );
};

export default News;
