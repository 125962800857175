import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import ServiceListHeader from '../serviceList/ServiceListHeader'

import './SquareList.css'
import NoImagePath from '../../../../assets/images/no-image-icon.png'

const SquareList = ({ title, menuId, content, divisionId }) => {
    const slides = content.map(el => (
        // TODO: link to blog/news and etc
        <Link to={`/trainingbase/${divisionId}/menu/${menuId}/content/${el.id}`} key={el.id} className='blogImgLink'> 
            <img className='blogImg' src={el.image ? el.image : NoImagePath} alt="" />
            <h6 className="image_des_1">{el.title}</h6>
        </Link>
    ));

    return (
        <div className='webBlogList'>
            <ServiceListHeader text={title} link='/news' btnText='רשימה מלאה' />
            <Swiper spaceBetween={30} slidesPerView={3}>
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        {slideContent}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default SquareList;