import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import "./GallerySwiper.css";

const GallerySwiper = ({ imgArr, slidesPerView, className, media }) => {
  return (
    <div className="swiperDiv">
      <Swiper
        spaceBetween={30}
        slidesPerView={slidesPerView}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className={className}
      >
        {imgArr.map((el, i) => {
          return (
            <SwiperSlide key={i}>
              <img alt="" src={media ? el : el.img} className={el.className} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default GallerySwiper;
