import React from "react";

// import ServiceListHeader from "../serviceList/ServiceListHeader";

import FeaturesSquare from "./FeaturesSquare";

const FeaturesList = (props) => {
  const departmentsListArr = [
    {
      img: "/images/hospital/info.jfif",
      title: "מידע שימושי",
      link: "#",
    },
    {
      img: "/images/hospital/news.jfif",
      title: "מה חדש?",
      link: "#",
    },
    {
      img: "/images/hospital/dish.jfif",
      title: "המחלקה שלנו",
      link: "#",
    },
  ];

  return (
    <>
      <div className="hospitalDepartments">
        {/* <ServiceListHeader text="המחלקות שלנו" btnText='לכל המחלקות' /> */}
        <div className="hospitalDepartmentsArr">
          {departmentsListArr.map((el, i) => {
            return (
              <FeaturesSquare
                img={el.img}
                link={el.link}
                title={el.title}
                key={i}
                // openSideBarComponent={props.openSideBarComponent}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FeaturesList;
