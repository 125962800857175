import { useState } from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={focused ? "welcome-greet focused" : "welcome-greet"}
      onMouseOver={() => {
        setFocused(true);
      }}
      onMouseOut={() => {
        setFocused(false);
      }}
    >
      <div className="title">ברוכים הבאים</div>
      <div className="subtitle">לאתר קריית ההדרכה!</div>
      <div className="text">
        באתר תוכלו למצוא את כל המידע הנחוץ על המרחב הציבורי של הקריה, <br />{" "}
        בבה”דים, שירותים כלליים ושירותי פנאי, המרכז המסחרי ועוד.{" "}
      </div>

      <Link to={"media"} className="button link media">
        למדיה
        <img alt="" className="icon" src="/images/media.c4d78bfe.svg" />
      </Link>
      {/* <Link to={"negev"} className="mabat">
				<div>בניהול</div>
				<img id="mabat" src={mabat} />

			</Link> */}
    </div>
  );
};

export default Welcome;
