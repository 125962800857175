import React, { useState } from "react";

import visaCard from "../../../../assets/images/pay/visa_card.png";
import masterCard from "../../../../assets/images/pay/master_card_1.png";

import "./PayNow.css";
import { Link } from "react-router-dom";

import Sidebar from "../../repeat/sidebar/Sidebar";

const PayNow = () => {
  const [selected, setSelected] = useState("yes");

  const [openAddCardSideBar, setOpenAddCardSideBar] = useState(false);

  const openSideBarComponent = (name) => {
    setOpenAddCardSideBar(name === "addcard");
  };

  const closeSideBarComponent = () => {
    setOpenAddCardSideBar(false);
  };

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <>
      <div className="PageDad">
        <div className="pay_now_pages rtl">
          <div className="pay_now_pages_area">
            <h1 className="pay_now_pages_area_h1">בחר את אמצעי התשלום</h1>
            <div className="pay_now_text_area">
              <div className="pay_now_card">
                <input
                  type={"text"}
                  className="pay_now_card_input"
                  placeholder="5555 **** **** **** 7777"
                />
                <img
                  src={visaCard}
                  className="pay_now_card_img"
                  alt="visa-card"
                />
                <input
                  type={"radio"}
                  name={"pay_now_radio_btn"}
                  className="pay_now_card_checkbox"
                  value="yes"
                  checked={selected === "yes"}
                  onChange={handleChange}
                />
              </div>
              <div className="pay_now_card">
                <input
                  type={"text"}
                  className="pay_now_card_input"
                  placeholder="9999 **** **** **** 2222"
                />
                <img
                  src={masterCard}
                  className="pay_now_card_img master_card"
                  alt="visa-card"
                />
                <input
                  type={"radio"}
                  name={"pay_now_radio_btn"}
                  className="pay_now_card_checkbox"
                  value="yes"
                  checked={selected === "yes"}
                  onChange={handleChange}
                />
              </div>
              <div className="pay_now_card">
                <input
                  type={"text"}
                  className="pay_now_card_input"
                  placeholder="3333 **** **** **** 5555"
                />
                <img
                  src={visaCard}
                  className="pay_now_card_img"
                  alt="visa-card"
                />
                <input
                  type={"radio"}
                  name={"pay_now_radio_btn"}
                  className="pay_now_card_checkbox"
                  value="yes"
                  checked={selected === "yes"}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add_new_card_div">
              <Link to="/addcard" className="add_new_card_h1">
                + הוספת כרטיס חדש
              </Link>
            </div>

            <div className="pay_with_balance_div">
              <h1 className="pay_with_balance_h1">תשלום בנקודות</h1>
              <h1 className="total_e_credits_earned_h1">
                סה"כ הכל נקודות: 147
              </h1>
            </div>

            <div className="pay_with_bonuses_div">
              <h1 className="pay_with_bonuses_h1">תשלום בבונוסים</h1>
              <h1 className="show_all_bonuses_h1">לפתיחת כל הבונוסים שלך +</h1>
            </div>

            <div className="pay_now_pages_box_btn">
              <div className="pay_now_pages_box_btn_area">
                <Link
                  onClick={() => openSideBarComponent("addcard")}
                  to={"#"}
                  className="pay_now_pages_box_go_btn"
                >
                  שלם עכשיו
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        openAddCardSideBar={openAddCardSideBar}
        setOpenAddCardSideBar={setOpenAddCardSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};
export default PayNow;
