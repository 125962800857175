import { MenuActionsTypes } from "./menu.types";

const INITIAL_STATE = {
  isMenuPending: false,
  list: [],
  menuError: false,

  isMenuByIdPending: false,
  menuById: {},
  menuByIdError: null,

  isMenuContentPending: false,
  menuContent: [],
  menuContentError: false,
};

const MenuReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MenuActionsTypes.GET.GET_MENU_PENDING:
      return {
        ...state,
        isMenuPending: true,
      };

    case MenuActionsTypes.GET.GET_MENU_SUCCESS:
      return {
        ...state,
        isMenuPending: false,
        list: action.payload,
        menuError: null,
      };

    case MenuActionsTypes.GET.GET_MENU_ERROR:
      return {
        ...state,
        isMenuPending: false,
        menuError: action.payload,
      };

    case MenuActionsTypes.GET.GET_MENU_BY_ID_PENDING:
      return {
        ...state,
        isMenuByIdPending: true,
      };

    case MenuActionsTypes.GET.GET_MENU_BY_ID_SUCCESS:
      return {
        ...state,
        isMenuByIdPending: false,
        menuContent: action.payload,
        menuContentError: null,
      };

    case MenuActionsTypes.GET.GET_MENU_BY_ID_ERROR:
      return {
        ...state,
        isMenuByIdPending: false,
        menuContentError: action.payload,
      };

    case MenuActionsTypes.GET.GET_MENU_CONTENT_PENDING:
      return {
        ...state,
        isMenuContentPending: true,
      };

    case MenuActionsTypes.GET.GET_MENU_CONTENT_SUCCESS:
      return {
        ...state,
        isMenuContentPending: false,
        menuContent: action.payload,
        menuContentError: null,
      };

    case MenuActionsTypes.GET.GET_MENU_CONTENT_ERROR:
      return {
        ...state,
        isMenuContentPending: false,
        menuContentError: action.payload,
      };

    default:
      return state;
  }
};

export default MenuReducer;
