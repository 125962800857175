import React, { useState } from "react";
import { Link } from "react-router-dom";

import Sidebar from "../../repeat/sidebar/Sidebar";

// import mail from "/images/doctor/envelope-icon.png";
// import facebook from "/images/doctor/facebook-icon.png";
// import twitter from "/images/doctor/twitter-icon.png";
// import review1 from "../../../assets/images/review1.png";
// import review2 from "../../../assets/images/review2.png";
// import arrow from "../../../assets/images/double_arrow_down.png";
// import singlearrow from "../../../assets/images/arrow.png";
// import three_dots from "../../../assets/images/black_three_dots.png";
// import ServiceList from "../../regularPages/serviceList/ServiceList";
// import envelope from "../../../assets/images/envelope-icon.png";
// import facebook from "../../../assets/images/facebook-icon.png";
// import twitter from "../../../assets/images/twitter-icon.png";
// import graduationcap from "../../../assets/images/graduation-cap.png";
// import graduationcapdark from "../../../assets/images/graduation-cap-dark.png";
// import star from "../../../assets/images/star-icon.png";
import NotFoundPath from "../../../../assets/images/no-image-icon.png";
import "./Commander.css";

const Commander = ({ commander }) => {
  const [openAppointmentCommanderSideBar, setAppointmentCommanderSideBar] =
    useState(false);

  const openSideBarComponent = (name) => {
    setAppointmentCommanderSideBar(name === "appointmentCommander");
  };

  const closeSideBarComponent = () => {
    setAppointmentCommanderSideBar(false);
  };

  return (
    <>
      <div className="PageDad">
        <div className="doctor_pages rtl">
          <div className="doctor_banner">
            <img
              src={commander?.image || NotFoundPath}
              alt=""
              className="commanderImg"
            />

            <Link to={"/"} className="back_arrow">
              {/* <img src={singlearrow} alt="arrow" /> */}
            </Link>
            <Link to={"#"} className="menu_btn">
              {/* <img src={three_dots} alt="three_dots" /> */}
            </Link>
            <div className="banner_content">
              <h1>{commander.title}</h1>
              <h6>{commander.description}</h6>
              <div className="doctor_heading align-center justify-between all_review_section">
                <button
                  className="all_reviews"
                  onClick={() => {
                    openSideBarComponent("appointmentCommander");
                  }}
                >
                  קביעת פגישה
                </button>
                <small>
                  {/* <img src={star} alt="" className="commanderImg" /> */}
                  {commander.rating} (1000 ביקורות){" "}
                </small>
              </div>
            </div>
          </div>
          <div className="doctors_sec doctors_details_sec">
            <div className="container">
              <div className="doctors_area">
                <div className="doctor_heading align-center justify-between">
                  <hr />
                  <h6>מידע</h6>
                </div>
                <p className="doc_desc">{commander?.jobTitle || ""}</p>
                <div className="chat_icon">
                  {/* <img src={chaticon} alt="" /> */}
                </div>
                <div className="doctor_heading align-center justify-between">
                  <hr />
                  <h6>ליצירת קשר</h6>
                </div>
                <div className="doctor_icons">
                  <img src="/images/doctor/envelope-icon.png" alt="" />

                  {commander?.facebook && (
                    <a
                      href={commander?.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/images/doctor/facebook-icon.png" alt="" />
                    </a>
                  )}
                  {commander?.twitter && (
                    <a
                      href={commander?.twitter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/images/doctor/twitter-icon.png" alt="" />
                    </a>
                  )}

                  {/* <p>
                    hana_levi@gmail.com <img src={envelope} alt="" />
                  </p>
                  <hr />
                  <p>
                    facebook.com/hanalevi <img src={facebook} alt="" />
                  </p>
                  <hr />
                  <p>
                    @hanalevi <img src={twitter} alt="" />
                  </p> */}

                  {/* <div className="doctor_heading align-center justify-between certificate">
                    <h6>תעודות</h6>
                  </div>
                  <p>
                    בוגרת אוניברסיטת תל אביב <img src={graduationcap} alt="" />
                  </p>
                  <hr />
                  <p>
                    תעודה מקצועית בתרפיה <img src={graduationcapdark} alt="" />
                  </p> */}
                </div>
              </div>
              <hr />
            </div>
          </div>

          {/* <ServiceList /> */}

          <div className="review_rating_sec">
            <div className="container">
              <div className="doctors_area">
                <div className="review_heading d-flex align-center justify-between">
                  <Link to="#" className="d-flex align-center">
                    {/* <img src={arrow} alt="double-arrow" /> */}
                    לכל התפקידים{" "}
                  </Link>
                  <h3>תפקידים קודמים</h3>
                </div>
                <div className="service_box_area">
                  <div className="service_box rating_box">
                    <div className="box_con review_box">
                      <div className="box_heading d-flex align-center justify-between">
                        <span>1/20-היום</span>
                        <h4>מב"ס בה"ד6</h4>
                      </div>
                      <span className="star">
                        {/* 5.0 <img src={star} alt="" /> */}
                      </span>
                      <div className="price d-flex align-center justify-between">
                        <p>רפורמה באימונים, הוספת מסלולים...</p>
                      </div>
                    </div>
                    <div className="box_round_img">
                      {/* <img src={review1} alt="Online" /> */}
                    </div>
                  </div>
                  <div className="service_box rating_box">
                    <div className="box_con review_box">
                      <div className="box_heading d-flex align-center justify-between">
                        <span>4/19-1/20</span>
                        <h4>סמח"ט גולני</h4>
                      </div>
                      <span className="star">
                        {/* 5.0 <img src={star} alt="" /> */}
                      </span>
                      <div className="price d-flex align-center justify-between">
                        <p>רפורמה באימונים, הוספת מסלולים...</p>
                      </div>
                    </div>
                    <div className="box_round_img">
                      {/* <img src={review2} alt="Online" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        openAppointmentCommanderSideBar={openAppointmentCommanderSideBar}
        setAppointmentCommanderSideBar={setAppointmentCommanderSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};

export default Commander;
