import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getDivisionMenu } from "../../../redux/API/division/division.action";
import { useDispatch, useSelector } from "react-redux";

import './Nav.css'
const Btn = (props) => {
  const url = useNavigate();
  const [className, setclassName] = useState("navBtn");
  const dispatch = useDispatch();

  useEffect(() => {

    const index = document.querySelector(".navBtn").baseURI.lastIndexOf("/");
    const page = document.querySelector(".navBtn").baseURI
      .slice(index);
    if (page === props.link) {
      setclassName("navBtn thisPage");
    } else {
      setclassName("navBtn");
    }
  }, [url]); // eslint-disable-line
  return (
    <div className="navBtnDiv nav">
      {props.mobile ? (
        <Link
          to={props.link}
          className={className}
          onClick={() => {
            props.id && dispatch(getDivisionMenu(props.id))
          }
            // props.const === "marketplace"
            //   ? () => {
            //     props.openSideBarComponent("marketplace");
            //     props.setOpenMobileMenu(false);
            //   }
            //   : () => props.setOpenMobileMenu(false)
          }
        >
          {props.text}
        </Link>
      ) : (
        <Link
          to={props.link}
          className={className}
          // onClick={
          //   props.const === "marketplace"
          //     ? () => props.openSideBarComponent("marketplace")
          //     : ""
          // }
          onClick={() => {
            props.id && dispatch(getDivisionMenu(props.id))
            // navigate(`${props.link}`)
          }}
        >
          {props.text}
        </Link>
      )
      }
    </div >
  );
};

export default Btn;
