import { useState, useEffect } from "react";
import SmallHeader from "../../repeat/SmallHeader";
import { getDivisionMenu } from "../../../../redux/actions-exporter";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

const cities = [
  "קריית שמונה",
  "חצור + יוקנעם",
  "עפולה",
  'חיפה',
  'בנימינה',
  "כפר יונה",
  "נתניה",
  "כפר סבא",
  'הרצליה',
  "פתח תקווה",
  'תל אביב',
  "חולון",
  "ראשון לציון",
  'ירושלים',
  'מודיעין',
  'אשדוד',
  'אשקלון',
  'בית שמש',
  'דימונה',
  'ירוחם',
  'באר שבע',
  'אילת',
]
const Shuttle = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [city, setCity] = useState('');
  const dispatch = useDispatch();
  const hours = useSelector((state) => state.division.divisionMenu[0]?.content
  );
  const divisionId = useSelector((state) => state.division?.divisionId)

  // const divisionMenu = useSelector((state) => state.division.divisionMenu);
  // .list.filter(i => i.entityId >= 1800));


  useEffect(() => {
    !divisionId === 2 && dispatch(getDivisionMenu(2));
  }, [dispatch]);
  /*const open = () => {
    const content = document.querySelector('.content')
    if (content.style.maxHeight === '') {
      content.style.maxHeight = '40vh'
      content.style.overflow = 'auto'

    } else {
      content.style.maxHeight = ''
      content.style.overflow = ''
    }
  }*/

  return (
    <div className="focused shuttle-schedule">
      <SmallHeader title="שאטלים ליום ראשון" />
      {/* <dropdown
        data="shared.json5#places"
        defaultvalue="בחר מיקום"
        tabindex="0"
      >
        <div className={openDropdown ? "content open" : "content"}>
          <div>
            <div>בחר מיקום</div>
            <div
              onClick={() => {
                setOpenDropdown(!openDropdown);
              }}
              className={openDropdown ? "retractable opened" : "retractable"}
              opt-id=""
            ></div>
          </div>
          {cities.sort().map((el) => (
            <div opt-id={el}
              className={city === el ? "selected" : ""}
              onClick={() => setCity(city)}
            >{el}</div>
          ))}
        </div>
      </dropdown> */}

      <div
        style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <icontext className="location" icon="/images/icon-location.svg">
          <img alt="" src="/images/icon-location.b55b841e.svg" className="icon" />
          {/* <a href="#" className="link"> */}
          <div className="text">מיקום</div>

          {/* </a> */}
        </icontext>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <select
            onChange={(e) => setCity(e.target.value)}>
            <option value="">בחר מיקום</option>
            {divisionId === 2 &&
              hours?.map((el) => (
                <option value={el.content}
                >{el.title}</option>
              ))}
          </select>
        </div>
      </div>

      {/* <div className="schedule"> */}
      <div
        style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>

        <icontext className="schedule" icon="/images/icon-time.svg">
          <img alt="" src="/images/icon-time.13cd3768.svg" className="icon" />
          <openinghours type="hours" className="text">
            זמן
          </openinghours>
        </icontext>
        <div
          style={{
            // display: 'flex', 
            // alignItems: 'center',
            margin: 'auto',
            minHeight: '90px'
          }}>
          {parse(city)}
        </div>
      </div>
      {/* <div className="category-separator"></div>  */}
    </div >
  );
};

export default Shuttle;
