import React from "react";
import { Link } from "react-router-dom";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useSelector } from "react-redux";

import SliderSectionSlider from "./_components/_slider";

import "./sliderSection.css";

const SliderSection = ({
  title,
  recommendedText,
  openSideBarComponent,
  merchantId,
}) => {
  const products = useSelector((state) => state.product.products);

  return (
    <section className="slider-section">
      <div className="doctor_heading d-flex align-center justify-between sec_heading_area flex-row-reverse">
        <Link
          onClick={() => openSideBarComponent("products", merchantId)}
          to={"#"}
          className="d-flex align-center flex-row-reverse"
        >
          <DoubleArrowIcon className="heading__arrow-icon" />
          {title}
        </Link>
        <h3>{recommendedText}</h3>
      </div>

      <SliderSectionSlider
        openSideBarComponent={openSideBarComponent}
        merchantId={merchantId}
        products={products.filter(
          (product) => product.merchantId === merchantId
        )}
      />
    </section>
  );
};

export default SliderSection;
