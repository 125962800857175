import { combineReducers } from "redux";

import MerchantsReducer from "./API/merchant/merchant.reducer";
import MenuReducer from "./API/menu/menu.reducer";
import ContentReducer from "./API/content/content.reducer";
import DivisionReducer from "./API/division/division.reducer";
import ProductReducer from "./API/product/product.reducer";
import ProductCategoryReducer from "./API/productCategories/productCategories.reducer";

export default combineReducers({
  merchants: MerchantsReducer,
  menu: MenuReducer,
  content: ContentReducer,
  division: DivisionReducer,
  product: ProductReducer,
  productCategories: ProductCategoryReducer,
});
