import { Link } from "react-router-dom";

import SmallHeader from "../repeat/SmallHeader";
import Video from "../home/Video";
import { Margin } from "@mui/icons-material";

const Faults = (props) => {
  return (
    <div
      className="tickets"
      displayname="services.json5#ticketsTitle"
      icon="tickets.svg"
      id="faults"
    >
      <div className="flexible">
        <div className="row">
          <div className="col-lg-4 col-sm-12 mt-3">
            <div className="col flexible">
              <SmallHeader title="תקלות" />
              <div className="locationText">
                נתקלתם בתקלה? התקשרו למוקד המרכזי ופתחו קריאה!
              </div>
              <icontext
                className="first-centered flex-end"
                icon="icon-phone-black.svg"
                onClick={() => {
                  // props.setOpenSideBar(!props.openSideBar);
                  props.openSideBarComponent("hospitalstay");
                }}
              >
                <img
                  alt=""
                  src="/images/icon-phone-black.033ad4fd.svg"
                  className="icon"
                />
                <div>
                  {" "}
                  הטלפון הצבאי: 2000
                  <br />
                  הטלפון האזרחי: 073-3772000
                  <br />
                  {/* לפתיחת קריאה:{" "}
                  <Link to={"#"} className="link">
                    <div className="text">לחץ כאן</div>
                  </Link> */}
                </div>
              </icontext>
            </div>
          </div>
          <div className="col-lg-8 col-sm-12">
            <div style={{ textAlign: 'center', width: '100%' }}>
              סרטון הסבר על המוקד המרכזי
            </div>
            <div className="expand flexible img">
              <div className="row">
                <div className="col-lg-6 col-sm-6 mt-3">
                  {/* <imgdisplay src="tickets.jpg" className="expand"> */}
                  <div className="images">
                    <img alt="" src="/images/tickets.36602eef.jpg" />
                  </div>
                  <div className="overlay">

                  </div>
                  {/* </imgdisplay> */}
                </div>
                <div className="col-lg-6 col-sm-6 mt-3">
                  <Video />

                  {/* <video
                    src="/images/tickets-hotline.aee51c34.mp4"
                    className="expand"
                    controls=""
                    poster="/images/tickets-hotline.4d5d4a5c.png"
                    preload="none"
                    type="video/mp4"
                  ></video> */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faults;
