import React from "react";

import ServiceListHeader from "../serviceList/ServiceListHeader";

import DepartmentsSquare from "./DepartmentsSquare";

const DepartmentsList = (props) => {
  const departmentsListArr = [
    {
      img: "/images/hospital/pc.jpg",
      title: "מחלקת תקשוב",
      link: "/department/3",
    },
    {
      img: "/images/hospital/afsenaut.jpg",
      title: "מחלקת אפסנאות",
      link: "/department/2",
    },
    {
      img: "/images/hospital/tikshuv.jpg",
      title: "מחלקת תקשורת",
      link: "/department/1",
    },
  ];

  return (
    <>
      <div className="hospitalDepartments">
        <ServiceListHeader text="המחלקות שלנו" btnText="לכל המחלקות" />
        <div className="hospitalDepartmentsArr">
          {departmentsListArr.map((el, i) => {
            return (
              <DepartmentsSquare
                img={el.img}
                link={el.link}
                title={el.title}
                key={i}
                openSideBarComponent={props.openSideBarComponent}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DepartmentsList;
