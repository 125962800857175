import React from "react";
import "./VandorDetails.css";
import Banner from "./banner/Banner";
import TabBtn from "../tabsMenu/TabBtn";
import VandorDetailsSquareList from "./VandorDetailsSquare/VandorDetailsSquareList";

const VandorDetails = () => {
  const tabsBtnsArr = [
    {
      text: "הכל",
    },
    {
      text: "חטיפים",
    },
    {
      text: "אלקטרוניקה",
    },
    {
      text: "בגדים",
    },
  ];

  const active = (e) => {
    const choosen = e.target.parentElement;
    const options = document.querySelectorAll(".tabsMenuLi");
    for (let i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }
    choosen.classList.add("active");
  };

  return (
    <>
      <div className="PageDad">
        <div className="vandor_details_pages rtl">
          <Banner />

          <div className="description_div">
            <h1 className="description_div_h1">תיאור</h1>
            <p className="description_div_p">
              כוורת - התחלנו מחנות קטנה שמוכרת חטיפים, ממתקים ושתייה קרה, אבל לא
              הפסקנו להתפתח, היום יש בחנות בגדים, מדים, כומתות, גלידות, משקאות
              חמים, מוצרי קוסמטיקה ואפילו מוצרי אלקטרוניקה.
            </p>
          </div>

          <section className="vandor_details_list_sec">
            <div className="tabs">
              <ul id="tabs-nav" className="list-unstyled">
                {tabsBtnsArr.map((el, i) => {
                  return (
                    <TabBtn active={active} text={el.text} key={i} i={i} />
                  );
                })}
              </ul>
              {/* <!-- END tabs-nav --> */}
              <div id="tabs-content">
                <div id="tab1" className="tab-content">
                  <VandorDetailsSquareList />
                </div>
              </div>
              {/* <!-- END tabs-content --> */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default VandorDetails;
