import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useDispatch, useSelector } from "react-redux";

import Filter from "./filter/Filter";
import { getMerchants } from "../../../redux/actions-exporter";
import Sidebar from "../repeat/sidebar/Sidebar";
import SliderSection from "./sliderSection";
import MarketPlaceTabs from "./tabsMenu/MarketPlaceTabs";

import "./MarketPlace.css";

const MarketPlace = () => {
  const [openVandorDetailsSideBar, setOpenVandorDetailsSideBar] =
    useState(false);
  const [openServiceDetailsSideBar, setOpenServiceDetailsSideBar] =
    useState(false);
  const [openProductsSideBar, setOpenProductsSideBar] = useState(false);
  const [openProductDetailsSideBar, setOpenProductDetailsSideBar] =
    useState(false);
  const [openCartSideBar, setOpenCartSideBar] = useState(false);
  const [openCheckoutSideBar, setOpenCheckoutSideBar] = useState(false);
  const [showfilter, setShowfilter] = useState(false);
  const [showdrp, setShowdrp] = useState(false);
  const [showshortdrp, setShowshortdrp] = useState(false);
  const [payload, setPayload] = useState(false);

  const merchants = useSelector((state) => state.merchants.merchants);

  const dispatch = useDispatch();

  const openSideBarComponent = (name, payload) => {
    setOpenVandorDetailsSideBar(name === "vandordetails");
    setOpenServiceDetailsSideBar(name === "servicedetails");
    setOpenProductsSideBar(name === "products");
    setOpenProductDetailsSideBar(name === "productDetails");
    setOpenCartSideBar(name === "cart");
    setOpenCheckoutSideBar(name === "checkout");
    setPayload(payload);
  };

  const closeSideBarComponent = () => {
    setOpenVandorDetailsSideBar(false);
    setOpenServiceDetailsSideBar(false);
    setOpenProductsSideBar(false);
    setOpenProductDetailsSideBar(false);
    setOpenCartSideBar(false);
    setOpenCheckoutSideBar(false);
    setPayload(false);
  };

  const messagesEndRef = useRef();
  const messagesEndRef1 = useRef();

  const scrollToBottom = () => {
    messagesEndRef1.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!merchants.length) dispatch(getMerchants());
  }, [dispatch]); // eslint-disable-line

  return (
    <>
      <div className="PageDad">
        <div className="marketpages rtl">
          <section className="search_sec">
            <div className="search_area">
              <Link
                to={"#"}
                className="filter_icon"
                onClick={() => {
                  setShowfilter(!showfilter);
                  if (showfilter) {
                    scrollToTop();
                  } else {
                    scrollToBottom();
                  }
                }}
              >
                <FilterListIcon />
              </Link>
              <div className="search_form">
                <button type="submit" className="btn_sub">
                  <SearchIcon className="SearchImg" />
                </button>
                <input
                  type="Search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חיפוש"
                />
              </div>
            </div>
          </section>

          <Filter
            showfilter={showfilter}
            setShowfilter={setShowfilter}
            scrollToTop={scrollToTop}
            scrollToBottom={scrollToBottom}
            showdrp={showdrp}
            setShowdrp={setShowdrp}
            showshortdrp={showshortdrp}
            setShowshortdrp={setShowshortdrp}
            messagesEndRef1={messagesEndRef1}
          />

          {merchants.map((merchant) => (
            <SliderSection
              title={merchant.title}
              recommendedText="פופולרי"
              openSideBarComponent={openSideBarComponent}
              merchantId={merchant.id}
            />
          ))}

          <section className="service_list_sec">
            <MarketPlaceTabs openSideBarComponent={openSideBarComponent} />
          </section>
          {/* <div className="category_list_sec">
            <h3>קטגוריות מומלצות</h3>
            <div className="category_list">
              <div className="list_box">
                <div className="box_img">
                  <img src={img} alt="images" />
                  <h6>הדרך ללימודים אקדמאים</h6>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Sidebar
        payload={payload}
        openVandorDetailsSideBar={openVandorDetailsSideBar}
        setOpenVandorDetailsSideBar={setOpenVandorDetailsSideBar}
        openServiceDetailsSideBar={openServiceDetailsSideBar}
        setOpenServiceDetailsSideBar={setOpenServiceDetailsSideBar}
        openProductsSideBar={openProductsSideBar}
        setOpenProductsSideBar={setOpenProductsSideBar}
        openProductDetailsSideBar={openProductDetailsSideBar}
        setOpenProductDetailsSideBar={setOpenProductDetailsSideBar}
        openCartSideBar={openCartSideBar}
        setOpenCartSideBar={setOpenCartSideBar}
        openCheckoutSideBar={openCheckoutSideBar}
        setOpenCheckoutSideBar={setOpenCheckoutSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};

export default MarketPlace;
