import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { isBrowser } from "react-device-detect";

import Sidebar from "../sidebar/Sidebar";
// import ChatBot from "../../chat/ChatBot";

import "./Navigator.css";
import toolsNotActive from "../../../../assets/images/icons/services/tools1.png";
import toolsActive from "../../../../assets/images/icons/services/tools1.png";

const Navigator = (props) => {
  // const ref = useRef(null);
  const displayOrNot = props.services;

  const [openHospitalSideBar, setOpenHospitalSideBar] = useState(false);

  const openSideBarComponent = (name) => {
    setOpenHospitalSideBar(name === "hospitalstay");
  };

  const closeSideBarComponent = () => {
    setOpenHospitalSideBar(false);
  };

  useEffect(() => {
    const header = document.getElementById("navigator");
    var sticky = header.offsetTop;

    window.onscroll = function () {
      myFunction();
    };

    function myFunction() {
      if (window.pageYOffset + 65 >= sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }, []);

  return (
    <>
      <div className="navigator" id="navigator">
        {props.navigatorArr.map((el, i) => {
          return (
            <ScrollLink
              offset={isBrowser ? -50 : -100}
              duration={50}
              smooth
              spy
              to={el.to}
              className={el.to}
              key={i}
            >
              <img alt="" src={el.imgNotActive} className="notActiveIcon" />
              <img alt="" src={el.imgActive} className="activeIcon" />
              <div className="hoverText">{el.hoverText}</div>
            </ScrollLink>
          );
        })}
        {/* <Link
          style={{ display: displayOrNot }}
          to={"#"}
          onClick={() => openSideBarComponent("hospitalstay")}
        >
          <img src={toolsNotActive} className="notActiveIcon" alt="tool" />
          <img src={toolsActive} className="activeIcon" alt="tool" />
          <div className="hoverText">תיקונים</div>
        </Link> */}
        {/*<ChatBot />*/}
      </div>
      <Sidebar
        openHospitalSideBar={openHospitalSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};
export default Navigator;
