export const ProductsActionTypes = {
  GET: {
    GET_PRODUCT_PENDING: "GET_PRODUCT_PENDING",
    GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
    GET_PRODUCT_ERROR: "GET_PRODUCT_ERROR",

    GET_PRODUCT_BY_ID_PENDING: "GET_PRODUCT_PENDING",
    GET_PRODUCT_BY_ID_SUCCESS: "GET_PRODUCT_SUCCESS",
    GET_PRODUCT_BY_ID_ERROR: "GET_PRODUCT_ERROR",
  },
};
