import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { getDivisions, getDivisionMenu } from "../../../redux/API/division/division.action";
import { useDispatch, useSelector } from "react-redux";
import SmallHeader from "../repeat/SmallHeader";
import parse from 'html-react-parser'

const Menu = () => {
  const menuArr1 = useSelector((state) => state.division?.divisionMenu)
  const dispatch = useDispatch();
  const divisionId = useSelector((state) => state.division?.divisionId)

  useEffect(() => {
    !divisionId === 16 && dispatch(getDivisionMenu(16));
  }, [dispatch]); // eslint-disable-line
  return (
    <div className="menu">
      <SmallHeader title="תפריט" text="תפריט לשבוע" />
      {divisionId === 16 &&
        menuArr1.sort((a, b) => a.id - b.id)
          .map((item, index) => (
            <Accordion key={index}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>
                  <Table responsive="sm">
                    <thead>
                      <tr>
                        <th>
                          <img src={"/images/s-morning.c53c11a0.svg"} />
                          בוקר
                        </th>
                        <th>
                          <img src={"/images/s-lunch.4b0ba03c.svg"} />
                          צהריים
                        </th>
                        <th>
                          <img src={"/images/s-evening.428ca55b.svg"} />
                          ערב
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="breakfast">
                        {item.content
                          .sort((a, b) => a.id - b.id)
                          .map((mill) => (
                            <td>
                              {parse(mill.content)}
                            </td>
                          ))}
                      </tr>
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
      {/* 
      <div className="flexible">
        <div className="expand x3">

        <Table responsive="sm">
          <thead>
            <tr >
              <th></th>
              {menuArr.map((item, index) => (
                <th key={index}>{item.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className='breakfast'>
              <td className='day'>בוקר</td>
              {menuArr.map((item, index) => (
                <td key={index}>
                  {item.breakfast.map((item, index) =>
                    <tr key={index}>
                      <td>
                        {item}
                      </td>
                    </tr>
                  )}
                </td>
              ))}
            </tr>
            <tr >
              <td className='day'>צהריים</td>
              {menuArr.map((item, index) => (
                <td key={index}>
                  {item.lunch.map((item, index) =>
                    <tr key={index}>
                      <td>
                        {item}
                      </td>
                    </tr>
                  )}
                </td>
              ))}
            </tr>
            <tr className='dinner'>
              <td className='day'>ערב</td>
              {menuArr.map((item, index) => (
                <td key={index}>
                  {item.dinner.map((item, index) =>
                    <tr key={index}>
                      <td>
                        {item}
                      </td>
                    </tr>
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
      </div>*/}
    </div>
  );
};

export default Menu;
