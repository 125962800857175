import React from "react";
import { Container } from "react-bootstrap";

import banner from "../../../../../assets/images/hospital/base.jpeg";

const BannerSqaure = (props) => {
  const { data: divider } = props;

  // const slides = bannerArr.map((el, i) => (
  const BannerElement = () => (
    <Container>
      <img
        src={banner}
        // src={divider.image} 
        className="bannerImg"
        alt="banner" />
      <div className="bannerDiv">
        <h1 className="bannerDivTitle">{divider.title}</h1>
        <p className="bannerDivDetail">{divider.description}</p>
        <div className="bannerDivDetailDiv">
          <p
            className="bannerDivDetailButton"
            onClick={() => {
              props.openSideBarComponent("about");
            }}
          >
            אודות
          </p>

          <p
            className="bannerDivDetailButton"
            onClick={() => {
              props.openSideBarComponent("reviews");
            }}
          >
            חוות דעת
          </p>

          <p
            className="bannerDivDetailButton"
            onClick={() => {
              props.openSideBarComponent("notebook");
            }}
          >
            פנקס כיס
          </p>
        </div>
      </div>
    </Container>
  );

  return (
    <>
      {/* <Swiper className="mySwiper" slidesPerView={1}
        initialSlide={`${props.id - 1}`}
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper> */}
      <BannerElement />
    </>
  );
};

export default BannerSqaure;
