import React, { useState } from "react";

import Sidebar from "../../repeat/sidebar/Sidebar";
import TabBtn from "../tabsMenu/TabBtn";
import VandorsSquareList from "../vandorsSquare/VandorsSquareList";

const Store = () => {
  const [openVandorDetailsSideBar, setOpenVandorDetailsSideBar] =
    useState(false);

  const openSideBarComponent = (name) => {
    setOpenVandorDetailsSideBar(name === "vandordetails");
  };

  const closeSideBarComponent = () => {
    setOpenVandorDetailsSideBar(false);
  };

  const tabsBtnsArr = [
    {
      text: "הכל",
    },
    {
      text: "בתי קפה",
    },
    {
      text: "מסעדות",
    },
    {
      text: "מכולות",
    },
  ];

  const active = (e) => {
    const choosen = e.target.parentElement;
    const options = document.querySelectorAll(".tabsMenuLi");
    for (let i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }
    choosen.classList.add("active");
  };

  return (
    <>
      <div className="PageDad">
        <div className="marketpages rtl">
          <section className="service_list_sec">
            <div className="tabs">
              <ul id="tabs-nav" className="tabsMenu list-unstyled">
                {tabsBtnsArr.map((el, i) => {
                  return (
                    <TabBtn active={active} text={el.text} key={i} i={i} />
                  );
                })}
              </ul>
              {/* <!-- END tabs-nav --> */}
              <section className="vandors_sec">
                <div className="doctor_heading d-flex align-center justify-between sec_heading_area flex-row-reverse">
                  <h3>חנויות פופולריות</h3>
                </div>

                <VandorsSquareList
                  openSideBarComponent={openSideBarComponent}
                />
              </section>
              <section className="vandors_sec">
                <div className="doctor_heading d-flex align-center justify-between sec_heading_area flex-row-reverse">
                  <h3>כל החנויות</h3>
                </div>

                <VandorsSquareList
                  openSideBarComponent={openSideBarComponent}
                />
              </section>
              {/* <!-- END tabs-content --> */}
            </div>
          </section>
        </div>
      </div>
      <Sidebar
        openVandorDetailsSideBar={openVandorDetailsSideBar}
        setOpenVandorDetailsSideBar={setOpenVandorDetailsSideBar}
        closeSideBarComponent={closeSideBarComponent}
      />
    </>
  );
};

export default Store;
