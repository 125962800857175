import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import "./ServiceListHeader.css";

const ServiceListHeader = ({ text, btnText }) => {
  return (
    <div
      className="service_heading d-flex align-center justify-between row-reverse"
      id="serviceListHeader"
    >
      <h3 className="align-center viewAll">
        {btnText}
        <DoubleArrowIcon />
      </h3>
      <h3>{text}</h3>
    </div>
  );
};

export default ServiceListHeader;
