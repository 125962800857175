import React from "react";

import ServiceListHeader from "../serviceList/ServiceListHeader";

import DepartmentsSquare from "./DepartmentsSquare";
import project1 from "./../../../../assets/images/negev/project1.jpg";
import project2 from "./../../../../assets/images/negev/project2.jpg";
import project3 from "./../../../../assets/images/negev/project3.jpg";

const DepartmentsList = (props) => {
  const departmentsListArr = [
    {
      img: project1,
      title: 'עיר הבה"דים',
      link: "#",
    },
    {
      img: project2,
      title: "פרויקט 2",
      link: "#",
    },
    {
      img: project3,
      title: "פרויקט 3",
      link: "#",
    },
  ];

  return (
    <>
      <div className="hospitalDepartments">
        <ServiceListHeader text="הפרויקטים שלנו" btnText="לכל הפרויקטים" />
        <div className="hospitalDepartmentsArr">
          {departmentsListArr.map((el, i) => {
            return (
              <DepartmentsSquare
                img={el.img}
                link={el.link}
                title={el.title}
                key={i}
                openSideBarComponent={props.openSideBarComponent}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DepartmentsList;
