import { Link } from "react-router-dom";

import transportNotActive from "../../../assets/images/icons/home/transport-not-active.svg";
import transportActive from "../../../assets/images/icons/home/transport-active.svg";
import diningNotActive from "../../../assets/images/icons/home/dining-not-active.svg";
import diningActive from "../../../assets/images/icons/home/dining-active.svg";
import commercialCentreNotActive from "../../../assets/images/icons/home/commercial-centre-not-active.svg";
import commercialCentreActive from "../../../assets/images/icons/home/commercial-centre-active.svg";
import recreationAndFitnessNotActive from "../../../assets/images/icons/home/recreation-and-fitness-not-active.svg";
import recreationAndFitnessActive from "../../../assets/images/icons/home/recreation-and-fitness-active.svg";
import generalServicesNotActive from "../../../assets/images/icons/home/general-services-not-active.svg";
import generalServicesActive from "../../../assets/images/icons/home/general-services-active.svg";
import militaryGearNotActive from "../../../assets/images/icons/home/military-gear-not-active.svg";
import militaryGearActive from "../../../assets/images/icons/home/military-gear-active.svg";
import healthcareNotActive from "../../../assets/images/icons/home/healthcare-not-active.svg";
import healthcareActive from "../../../assets/images/icons/home/healthcare-active.svg";

const Services = (props) => {
  return (
    <div className="services">
      <decoratedtitle>
        <div className="title">שירותי הקריה</div>
        <img
          alt=""
          className="title-underline"
          src="/images/title-black.07ae5174.svg"
        />
      </decoratedtitle>
      <div className="links">
        <div className="transport">
          <Link to={"transport"} className="link">
            <img
              className="transportNotActive"
              src={transportNotActive}
              alt="transport"
            />
            <img
              className="transportActive"
              src={transportActive}
              alt="transport"
            />
          </Link>
          היסעים
        </div>
        <div className="dining">
          <Link to={"dining"} className="link">
            <img
              className="diningNotActive"
              src={diningNotActive}
              alt="transport"
            />
            <img className="diningActive" src={diningActive} alt="transport" />
          </Link>
          הסעדה
        </div>
        {/* <div className="commercialCentre">
          <Link
            onClick={() => {
              props.openSideBarComponent("marketplace");
            }}
            to="#"
            // to={"commercial-centre"}
            className="link"
          >
            <img
              className="commercialCentreNotActive"
              src={commercialCentreNotActive}
              alt="transport"
            />
            <img
              className="commercialCentreActive"
              src={commercialCentreActive}
              alt="transport"
            />
          </Link>
          המרכז המסחרי
        </div> */}
        <div className="recreationAndFitness">
          <Link to={"recreation-and-fitness"} className="link">
            <img
              className="recreationAndFitnessNotActive"
              src={recreationAndFitnessNotActive}
              alt="transport"
            />
            <img
              className="recreationAndFitnessActive"
              src={recreationAndFitnessActive}
              alt="transport"
            />
          </Link>
          פנאי ותרבות
        </div>
        <div className="services">
          <Link to={"services"} className="link">
            <img
              className="generalServicesNotActive"
              src={generalServicesNotActive}
              alt="transport"
            />
            <img
              className="generalServicesActive"
              src={generalServicesActive}
              alt="transport"
            />
          </Link>
          שירותים כללים
        </div>
        <div className="equipment">
          <Link to={"equipment"} className="link">
            <img
              className="militaryGearNotActive"
              src={militaryGearNotActive}
              alt="transport"
            />
            <img
              className="militaryGearActive"
              src={militaryGearActive}
              alt="transport"
            />
          </Link>
          ציוד צבאי
        </div>
        <div className="healthcare">
          <Link to={"healthcare"} className="link">
            <img
              className="healthcareNotActive"
              src={healthcareNotActive}
              alt="transport"
            />
            <img
              className="healthcareActive"
              src={healthcareActive}
              alt="transport"
            />
          </Link>
          רפואה
        </div>
      </div>
    </div>
  );
};

export default Services;
