import { isMobile } from "react-device-detect";

import Header from "../repeat/Header";
import Navigator from "../repeat/newNavigator/Navigator";
import Clinic from "./Clinic";
import Medical from "./Medical";
import CallCenter from "./CallCenter";
import DoctorVisit from "./DoctorVisit";
import GallerySwiper from "../repeat/gallerySwiper/GallerySwiper";

import "./Healthcare.css";
import medkitNotActive from "../../../assets/images/icons/healthcare/medkit.ab1d1c15.svg";
import medicalCentreNotActive from "../../../assets/images/icons/healthcare/medical-centre.629be216.svg";
import medicalCallCentreNotActive from "../../../assets/images/icons/healthcare/medical-call-centre.124a4787.svg";
import doctorVisitsNotActive from "../../../assets/images/icons/healthcare/doctor-visits.13c60dd4.svg";
import medkitActive from "../../../assets/images/icons/healthcare/medkit-highlight.3a2b7234.svg";
import medicalCentreActive from "../../../assets/images/icons/healthcare/medical-centre-highlight.d147781a.svg";
import medicalCallCentreActive from "../../../assets/images/icons/healthcare/medical-call-centre-highlight.6338fbad.svg";
import doctorVisitsActive from "../../../assets/images/icons/healthcare/doctor-visits-highlight.36da9c17.svg";

const Healthcare = () => {
  const navigatorArr = [
    {
      to: "clinic",
      hoverText: "מרפאה ",
      imgActive: medkitActive,
      imgNotActive: medkitNotActive,
    },
    {
      to: "medical-centre",
      hoverText: 'מרפ"א',
      imgActive: medicalCentreActive,
      imgNotActive: medicalCentreNotActive,
    },
    {
      to: "medical-call-centre",
      hoverText: "מקול הלב",
      imgActive: medicalCallCentreActive,
      imgNotActive: medicalCallCentreNotActive,
    },
    {
      to: "doctor-visits",
      hoverText: "ביקורופא",
      imgActive: doctorVisitsActive,
      imgNotActive: doctorVisitsNotActive,
    },
  ];

  const imgArr = [
    {
      img: "/images/710_8865.000b4723.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/750_0129.7ae763e8.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/750_0131.897d7e78.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/750_9254.0ca91f7e.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/_TIL1832.25fa8d51.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/_TIL1836.a2c5bbdf.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/_TIL1837.47be2d63.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/_TIL1856.2c4fd9a3.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/_TIL1854.1c4d3ef1.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/_TIL1861.c93045b5.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/_TIL7847.0ec0f366.jpg",
      className: "bigGallery",
    },
    {
      img: "/images/_TIL7875.d447ff4c.jpg",
      className: "bigGallery",
    },
  ];

  return (
    <div className="healthcare page">
      <Header
        header="רפואה"
        text="התחלתם להרגיש לא טוב? דאגו לבריאותכם וקבעו תור למרפאה בהקדם!"
      />
      <navigatable>
        <div className="content">
          <Navigator navigatorArr={navigatorArr} />
          <div className="textArea">
            <Clinic />
            <Medical />
            <CallCenter />
            <DoctorVisit />
          </div>
        </div>
      </navigatable>
      <GallerySwiper
        imgArr={imgArr}
        slidesPerView={isMobile ? 1 : 3}
        className="mySwiper bigGallery"
      />
    </div>
  );
};

export default Healthcare;
