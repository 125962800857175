// import './code/generalStyle.css'
// import './code/opening.css'
// import './code/home.css'
// import './code/about.css'
// import './code/learning.css'
// import './code/exam.css'

const Game = (props) => {
  return <iframe title="game" src={props.game} />;
};

export default Game;
