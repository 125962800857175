import React from "react";

import "./Banner.css";

import BannerSqaure from "./bannerSqaure/BannerSqaure";

const Banner = (props) => {
  return (
    <>
      <div className="hospitalWebBanner">
        <BannerSqaure id={props.id} />
      </div>
    </>
  );
};

export default Banner;
