import { ProductsActionTypes } from "./product.types";

const INITIAL_STATE = {
  isProductsPending: false,
  products: [],
  productsError: false,

  isProductByIdPending: false,
  productById: {},
  productByIdError: false,
};

const ProductsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ProductsActionTypes.GET.GET_PRODUCT_PENDING:
      return {
        ...state,
        isProductsPending: true,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        isProductsPending: false,
        products: action.payload,
        productsError: null,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_ERROR:
      return {
        ...state,
        isProductsPending: false,
        productsError: action.payload,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_PENDING:
      return {
        ...state,
        isProductByIdPending: true,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        isProductByIdPending: false,
        productById: action.payload,
        productByIdError: null,
      };

    case ProductsActionTypes.GET.GET_PRODUCT_BY_ID_ERROR:
      return {
        ...state,
        isProductByIdPending: false,
        productByIdError: action.payload,
      };

    default:
      return state;
  }
};

export default ProductsReducer;
