import { ContentActionsTypes } from "./content.types";

const INITIAL_STATE = {
  isContentPending: false,
  list: [],
  contentError: false,

  isContentByIdPending: false,
  contentById: [],
  contentByIdError: null,
};

const ContentReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ContentActionsTypes.GET.GET_CONTENT_PENDING:
      return {
        ...state,
        isContentPending: true,
      };

    case ContentActionsTypes.GET.GET_CONTENT_SUCCESS:
      return {
        ...state,
        isContentPending: false,
        list: action.payload,
        contentError: null,
      };

    case ContentActionsTypes.GET.GET_CONTENT_ERROR:
      return {
        ...state,
        isContentPending: false,
        contentError: action.payload,
      };

    case ContentActionsTypes.GET.GET_CONTENT_BY_ID_PENDING:
      return {
        ...state,
        isContentByIdPending: true,
      };

    case ContentActionsTypes.GET.GET_CONTENT_BY_ID_SUCCESS:
      return {
        ...state,
        isContentByIdPending: false,
        contentById: action.payload,
        contentByIdError: null,
      };

    case ContentActionsTypes.GET.GET_CONTENT_BY_ID_ERROR:
      return {
        ...state,
        isContentByIdPending: false,
        contentByIdError: action.payload,
      };

    default:
      return state;
  }
};

export default ContentReducer;
