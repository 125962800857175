import React, { useState } from "react";

import "./Training.css";
import Header from "../repeat/Header";
import Navigator from "../repeat/newNavigator/Navigator";
import Sidebar from "./../repeat/sidebar/Sidebar";
import TrainingCardList from "./trainingCardList/TrainingCardList";

import mifkada from "../../../assets/images/icons/training/bhdmifkada.a17b93f0.svg";
import bhd6 from "../../../assets/images/icons/training/bhd6.33658e51.svg";
import bhd10 from "../../../assets/images/icons/training/bhd10.ee64a6be.svg";
import bhd11 from "../../../assets/images/icons/training/bhd11.7fee82a1.svg";
import bhdhinuh from "../../../assets/images/icons/training/bhdhinuh.766d48a6.svg";
import bhd13 from "../../../assets/images/icons/training/bhd13.6f5e59bc.svg";
import bhd20 from "../../../assets/images/icons/training/bhd20.66240070.svg";

import bdikotShigra from "../../../assets/images/training/projects/בדיקות שגרה.jpeg";
import btihut from "../../../assets/images/training/projects/בטיחות.jpeg";
import kidslife from "../../../assets/images/training/projects/החייאה ילדים.jpeg";
import adoltslife from "../../../assets/images/training/projects/החייאה מבוגר.jpeg";
import prison from "../../../assets/images/training/projects/זכויות וחובות הכלוא.jpeg";
import body from "../../../assets/images/training/projects/יסודות מבנה הגוף.jpeg";
import zbook from "../../../assets/images/training/projects/יסודות מבנה הגוף.jpeg";
import hamets from "../../../assets/images/training/projects/מבנה חמץ.jpeg";
import chrony from "../../../assets/images/training/projects/מחלות כרוניות.jpeg";
import drive from "../../../assets/images/training/projects/מחשבון נהיגה.jpeg";
import goals1 from "../../../assets/images/training/projects/מטרות1.jpeg";
import dati from "../../../assets/images/training/projects/מידע לחייל נדתי כרטיסיות.jpeg";
import hakhyaa from "../../../assets/images/training/projects/מכשירים החייאה.jpeg";
import hovala from "../../../assets/images/training/projects/מערכת ההובלה.jpeg";
import breath from "../../../assets/images/training/projects/מערכת הנשימה.jpeg";
import digest from "../../../assets/images/training/projects/מערכת העיכול.jpeg";
import nervs from "../../../assets/images/training/projects/מערכת העצבים.jpeg";
import dahuf from "../../../assets/images/training/projects/מצביםפ דחופים בשגרה.jpeg";
import nohalyehida from "../../../assets/images/training/projects/נהלי יחידה.jpeg";
import adultsig from "../../../assets/images/training/projects/סיגלוז מבוגר.jpeg";
import teeth from "../../../assets/images/training/projects/סייעות שיניים.jpeg";
import agree from "../../../assets/images/training/projects/סימנים מוסכמים.jpeg";
import skarim from "../../../assets/images/training/projects/סקרים מתקדמים.jpeg";
import sleng from "../../../assets/images/training/projects/עגה עבריינית.jpeg";
import shrir from "../../../assets/images/training/projects/עור שתן שלד שריר.jpeg";
import medicine from "../../../assets/images/training/projects/עקרונות הטיפול התרופתי.jpeg";
import kinematics from "../../../assets/images/training/projects/קינימטיקה.jpeg";
import talabq from "../../../assets/images/training/projects/שאלות בנושא תלב.jpeg";
import bilush from "../../../assets/images/training/projects/שאלות לסיכום קורס בילוש.jpeg";
import standart from "../../../assets/images/training/projects/תקן 15.jpeg";
import kinematicsPractice from "../../../assets/images/training/projects/תרגול קינימטיקה.jpeg";
import cdePractice from "../../../assets/images/training/projects/CDE תרגול.jpeg";
import CDE from "../../../assets/images/training/projects/CDE.jpeg";
import sabPractice from "../../../assets/images/training/projects/SAB תרגול.jpeg";
import SAB from "../../../assets/images/training/projects/SAB.jpeg";

const Training = () => {
  const [openAppointmentSideBar, setOpenAppointmentSideBar] = useState(false);
  const [openHospitalSideBar, setOpenHospitalSideBar] = useState(false);
  const [openGameSideBar, setOpenGameSideBar] = useState(false);
  const [game, setGame] = useState();

  const openSideBarComponent = (name) => {
    setOpenAppointmentSideBar(name === "appointment");
    setOpenHospitalSideBar(name === "hospitalstay");
    setOpenGameSideBar(name === "game");
  };

  const closeSideBarComponent = () => {
    setOpenAppointmentSideBar(false);
    setOpenHospitalSideBar(false);
    setOpenGameSideBar(false);
  };

  const chooseGame = (game) => {
    setGame(game);
  };

  const trainingArr = [
    {
      baseName: "תבניות ללומדות",
      id: "templates",
      bahadArr: [
        {
          year: "2020",
          title: "מושגון",
          description:
            "הנגשת מושגים מעולה בדרך נוחה שמאפשרת לימוד של המושגים במגוון דרכים.",
          type: "מושגון",
          link: "https://mador-till-prod.github.io/generic-product/generic-quizlet-prod/",
          img: medicine,
        },
        {
          year: "2021",
          title: "כרטיסיות עם ציון",
          description: "תוצר המנגיש מידע באופן המקל על הלמידה והשינון",
          type: "כרטיסיות עם ציון",
          link: "https://mador-till-prod.github.io/generic-product/cards-with-grade/code/",
          img: prison,
        },
      ],
    },
    {
      baseName: "מפקדה",
      id: "mifkada",
      bahadArr: [
        {
          year: "2020",
          title: 'שאלות בנושא תל"ב',
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://madortill.github.io/who-wants-to-be-talab/",
          img: talabq,
        },
        // {
        // 	"year": "2020",
        // 	"title": "אתר קריית ההדרכה",
        // 	"description": "אתר שמנגיש ומציג את קריית ההדרכה והשירותים שמסופקים בה",
        // 	"type": "תוצר ייעודי",
        // 	"link": "https://mador-till-prod.github.io/ctc-site/",
        // 	img: kriyat,
        // },
        {
          year: "2021",
          title: "מידע לחייל הדתי",
          description:
            "תוצר המנגיש מידע ושאלות על זכויותיהם של חיילים דתיים, מיועד לחיילים עצמם ומפקדיהם.",
          type: "כרטיסיות",
          link: "https://mador-till-prod.github.io/midaLhaialHadati/code/",
          img: dati,
        },
        {
          year: "2021",
          title: "נהלי יחידה",
          description:
            "פנקס כיס דיגיטלי, המכיל את כל זכויות וחובות החיילים בקריית ההדרכה",
          type: "כרטיסיות",
          link: "https://mador-till-prod.github.io/web-basseRules-mifkada/code/index.html",
          img: nohalyehida,
        },
      ],
    },
    {
      baseName: 'בה"ד 6',
      id: "bhd6",
      bahadArr: [
        {
          year: "2020",
          title: "מחשבון נהיגה",
          description:
            'תור המיג חומר שחשוב לבדיקת אפשרות למעבר בין סוגי רישיונות בעזרת סינונים ובצורה ברורה יותר מהפקודה. התוצר מופץ בכלל צה"ל',
          type: "תוצר ייעודי",
          link: "https://madortill.github.io/BHD6DrivingCalculator/",
          img: drive,
        },
        {
          year: "2021",
          title: "כרך ז",
          description: "",
          type: "תוצר ייעודי",
          link: "http://driveappbhd.herokuapp.com/#/home",
          img: zbook,
        },
      ],
    },
    {
      baseName: 'בה"ד 10',
      id: "bhd10",
      bahadArr: [
        {
          year: "2020",
          title: "יסודות מבנה הגוף",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/Body-structure",
          img: body,
        },
        {
          year: "2020",
          title: "מכשירים",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/Machshirim",
          img: hakhyaa,
        },
        {
          year: "2020",
          title: 'סילגו"ז מבוגר',
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/adult-silgoz",
          img: adultsig,
        },
        {
          year: "2020",
          title: "מערכת הנשימה",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/breath-system",
          img: breath,
        },
        {
          year: "2020",
          title: "החייאה מבוגר",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/hachyaa-adult",
          img: adoltslife,
        },
        {
          year: "2020",
          title: "החייאה ילדים",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/hachyaa-children",
          img: kidslife,
        },
        {
          year: "2020",
          title: "מערכת העיכול",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/haykol",
          img: digest,
        },
        {
          year: "2020",
          title: "מערכת ההובלה",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/hovala",
          img: hovala,
        },
        {
          year: "2020",
          title: "מערכת העצבים",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/nerve-system",
          img: nervs,
        },
        {
          year: "2020",
          title: "מערכות עור, שתן, שלד ושריר",
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/sheten-oor-sheled",
          img: shrir,
        },
        {
          year: "2020",
          title: 'סילגו"ז מבוגר',
          description:
            "תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר",
          type: "מי רוצה להיות מליונר",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/silgoz-children",
          img: adultsig,
        },
        {
          year: "2020",
          title: "קינימטיקה",
          description:
            'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה',
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/kinematics",
          img: kinematics,
        },
        {
          year: "2020",
          title: "קינימטיקה תרגול",
          description: "חלק מתרגל מתוך התוצר השלם של קינימטיקה",
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/Kinematics-exer",
          img: kinematicsPractice,
          isItOpenPopup: true,
        },
        {
          year: "2020",
          title: "מצבים דחופים בשגרה",
          description:
            "תוצר המכיל סימולציות של חופל ומטופל שמתרגל את החניכים על אופן טיפול, ושאילת שאלות נכונה.",
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/Matzavim-Dhufim-Bashigra",
          img: dahuf,
        },
        {
          year: "2020",
          title: "בדיקות שגרה",
          description:
            'התוצר פותח עבור קורס חובשים בבה"ד 10. התוצר ייעודי ומשמש כתרגול ביניים בשיעור בין למידת החומר התיאורטי ותרגול מעשי עם המפקד',
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/Routine-testing",
          img: bdikotShigra,
        },
        {
          year: "2020",
          title: "סקרים מתקדמים",
          description:
            'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה',
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/skarim-mitkadmim",
          img: skarim,
        },
        {
          year: "2020",
          title: "סקרים מתקדמים תרגול",
          description:
            "חלק מתרגל מתוך התוצר השלם של סקרים מתקדמים (עליו התבסס התוצר של טופסולוגיה)",
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/Skarim-mitkadmim-exer",
          img: skarim,
        },
        {
          year: "2020",
          title: "עקרונות הטיפול התרופתי",
          description:
            "תוצר המנגיש מושגים ברפואים ב-4 דרכי למידה שונות לבחירת המשתמש.",
          type: "מושגון",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/The-principles-of-drug",
          img: medicine,
        },
        {
          year: "2020",
          title: "CDE",
          description:
            'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה',
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/cde/",
          img: CDE,
        },
        {
          year: "2020",
          title: "CDE תרגול",
          description: "חלק מתרגל מתוך התוצר השלם של CDE",
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/cde-exer",
          img: cdePractice,
          isItOpenPopup: true,
        },
        {
          year: "2020",
          title: "מחלות כרוניות",
          description:
            'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה',
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/chronic-diseases",
          img: chrony,
        },
        {
          year: "2020",
          title: "SAB",
          description:
            'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה',
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/sab",
          img: SAB,
        },
        {
          year: "2020",
          title: "SAB תרגול",
          description: "חלק מתרגל מתוך התוצר השלם של SAB",
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/BHD-10-Medics/sab-exer",
          img: sabPractice,
          isItOpenPopup: true,
        },
        {
          year: "2021",
          title: "תקן 15",
          description:
            "תוצר מנגיש ידע ומתרגל רשימה של מעל 100 תרופות שרשאי חובש לנפק יחד עם ההתוויות שעליו להגיד.",
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/teken15/code/",
          img: standart,
        },
        {
          year: "2021",
          title: "סייעות שיניים- מושגים",
          description:
            "תוצר שמנגיש מושגים במגוון נושאים במספר דרכי למידה לבחירת המשתמש.",
          type: "מושגון",
          link: "https://mador-till-prod.github.io/dental-assistants/",
          img: teeth,
        },
      ],
    },
    {
      baseName: 'בה"ד 11',
      id: "bhd11",
      bahadArr: [
        {
          year: "2022",
          title: "חוסן למפקד",
          description: "",
          type: "כרטיסיות",
          link: "https://mador-till-prod.github.io/hosen_lamefaked_bhd11/code/",
        },
        {
          year: "2022",
          title: "זכויות החייל",
          description: "",
          type: "כרטיסיות",
          link: "https://mador-till-prod.github.io/lomdat_zchout_hachayal_bhd_11/code/",
        },
      ],
    },
    {
      baseName: 'בה"ד חינוך',
      id: "bhdhinuh",
      bahadArr: [
        {
          year: "2021",
          title: "לומדת מטרות",
          description:
            'תוצר ייעודי עבור קורס הו"ד בבה"ד חינוך. התוצר מקנה ידע ומתרגל בצורה משחקית כתיבה נכונה של מטרות שיעור.',
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/targets/main.html",
          img: goals1,
        },
      ],
    },
    {
      baseName: 'בה"ד 13',
      id: "bhd13",
      bahadArr: [
        {
          year: "2021",
          title: "שאלות לסיכום קורס בילוש",
          description:
            "תוצר המנגיש שאלות לסיכום הקורס בצורה חוויתית ומשחקית, בטלפוני החניכים.",
          type: "המירוץ לבסיס",
          link: "https://mador-till-prod.github.io/race-bhd13-detective-course/main.html",
          img: bilush,
        },
        {
          year: "2021",
          title: "עגה עבריינית",
          description:
            "תוצר המנגיש ביטויים ומילים הנהוגות אצל עבריינים למדריכי כליאה כדי שיכירו ויזהו אותם במהלך שירותם.",
          type: "כרטיסיות",
          link: "https://mador-till-prod.github.io/agaAvarianit/code/",
          img: sleng,
        },
        {
          year: "2021",
          title: "זכויות וחובות הכלוא",
          description:
            "תוצר שנגיש ידע על כל זכויות וחובות של כלואים. מיועד לכלואים ולמדריכי הכליאה",
          type: "כרטיסיות",
          link: "https://mador-till-prod.github.io/prisoner-rights-bhd13/code/",
          img: prison,
        },
        {
          year: "2021",
          title: "מבנה חמץ",
          description:
            'תוצר שפותח בשיתוף פעולה עם ההשלמה של בה"ד 13, תוצר המסביר על מבנה חמ"ץ תוכן עליו נבחנים ביום הראשון של ההשלמה.',
          type: "כרטיסיות",
          link: "https://mador-till-prod.github.io/bhd13-hanatz/code/",
          img: hamets,
        },
      ],
    },
    {
      baseName: 'בה"ד 20',
      id: "bhd20",
      bahadArr: [
        {
          year: "2021",
          title: "סימנים מוסכמים",
          description:
            "תוצר אשר מקנה ידע ומתרגל. המותאם לטלפונים ניידים. בתוצר משולבים סרטונים וגיפים ליצירת המחשה ויזואלית מלאה של הסימנים.",
          type: "תוצר ייעודי",
          link: "https://mador-till-prod.github.io/agreedSigns20/",
          img: agree,
        },
        {
          year: "2021",
          title: "בטיחות",
          description:
            'לומדה שמשלבת סרטונים שפותחו בבה"ד 20 ושאלות. התוצר הוא כלל צהלי ומנגיש מידע בטיחותי',
          type: "כרטיסיות",
          link: "https://mador-till-prod.github.io/lomda-safety-general/code/index.html",
          img: btihut,
        },
      ],
    },
  ];

  const navigatorArr = [
    {
      to: "mifkada",
      hoverText: "מפקדה",
      imgActive: mifkada,
      imgNotActive: mifkada,
    },
    {
      to: "bhd6",
      hoverText: 'בה"ד 6',
      imgActive: bhd6,
      imgNotActive: bhd6,
    },
    // {
    // 	to: 'bhd7',
    // 	hoverText: 'בה"ד 7',
    // 	imgActive: bhd7,
    // 	imgNotActive: bhd7,
    // },
    {
      to: "bhd10",
      hoverText: 'בה"ד 10',
      imgActive: bhd10,
      imgNotActive: bhd10,
    },
    {
      to: "bhd11",
      hoverText: 'בה"ד 11',
      imgActive: bhd11,
      imgNotActive: bhd11,
    },
    {
      to: "bhdhinuh",
      hoverText: 'בה"ד חינוך',
      imgActive: bhdhinuh,
      imgNotActive: bhdhinuh,
    },
    {
      to: "bhd13",
      hoverText: 'בה"ד 13',
      imgActive: bhd13,
      imgNotActive: bhd13,
    },
    {
      to: "bhd20",
      hoverText: 'בה"ד 20',
      imgActive: bhd20,
      imgNotActive: bhd20,
    },
  ];

  // do not delete לא למחוק!!!
  // 	let try1 = [
  // 		{
  // 			baseName: 'מפקדה',
  // 			id: 'mifkada',
  // 			bahadArr:
  // 			[

  // 			],
  // 		},
  // 		{
  // 			baseName: 'בה"ד 6',
  // 			id: 'bhd6',
  // 			bahadArr:
  // 			[

  // 			],
  // 		},
  // 		// {
  // 		// 	baseName: 'בה"ד 7',
  // 		// 	id: 'bhd7',
  // 		// 	bahadArr:
  // 		// 	[

  // 		// 	],
  // 		// },
  // 		{
  // 			baseName: 'בה"ד 10',
  // 			id: 'bhd10',
  // 			bahadArr:
  // 			[

  // 			],
  // 		},
  // 		{
  // 			baseName: 'בה"ד 11',
  // 			id: 'bhd11',
  // 			bahadArr:
  // 			[

  // 			],
  // 		},
  // 		{
  // 			baseName: 'בה"ד חינוך',
  // 			id: 'bhdhinuh',
  // 			bahadArr:
  // 			[

  // 			],
  // 		},
  // 		{
  // 			baseName: 'בה"ד 13',
  // 			id: 'bhd13',
  // 			bahadArr:
  // 			[

  // 			],
  // 		},
  // 		{
  // 			baseName: 'בה"ד 20',
  // 			id: 'bhd20',
  // 			bahadArr:
  // 			[

  // 			],
  // 		},
  // 	]

  // 	const arr = [
  // 		[
  // 			["2020","מפקדה",'שאלות בנושא תל"ב',"תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://madortill.github.io/who-wants-to-be-talab/"],
  // 			["2020","מפקדה","אתר קריית ההדרכה","אתר שמנגיש ומציג את קריית ההדרכה והשירותים שמסופקים בה","תוצר ייעודי","https://mador-till-prod.github.io/ctc-site/"],
  // 			["2021","מפקדה","מידע לחייל הדתי","תוצר המנגיש מידע ושאלות על זכויותיהם של חיילים דתיים, מיועד לחיילים עצמם ומפקדיהם.","כרטיסיות","https://mador-till-prod.github.io/midaLhaialHadati/code/"],
  // 			["2021","מפקדה","נהלי יחידה","פנקס כיס דיגיטלי, המכיל את כל זכויות וחובות החיילים בקריית ההדרכה","כרטיסיות","https://mador-till-prod.github.io/web-basseRules-mifkada/code/index.html"],
  // 		],
  // 		[
  // 			["2020","\"בה\"\"ד 6\"","מחשבון נהיגה",'תור המיג חומר שחשוב לבדיקת אפשרות למעבר בין סוגי רישיונות בעזרת סינונים ובצורה ברורה יותר מהפקודה. התוצר מופץ בכלל צה"ל',"תוצר ייעודי","https://madortill.github.io/BHD6DrivingCalculator/"],
  // 			["2021","\"בה\"\"ד 6\"","כרך ז","אין תיאור","תוצר ייעודי","http://driveappbhd.herokuapp.com/#/home"],
  // 		],
  // 		[
  // 			["2020","\"בה\"\"ד 10\"","יסודות מבנה הגוף","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/Body-structure"],
  // 			["2020","\"בה\"\"ד 10\"","מכשירים","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/Machshirim"],
  // 			["2020","\"בה\"\"ד 10\"",'סילגו"ז מבוגר',"תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/adult-silgoz"],
  // 			["2020","\"בה\"\"ד 10\"","מערכת הנשימה","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/breath-system"],
  // 			["2020","\"בה\"\"ד 10\"","החייאה מבוגר","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/hachyaa-adult"],
  // 			["2020","\"בה\"\"ד 10\"","החייאה ילדים","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/hachyaa-children"],
  // 			["2020","\"בה\"\"ד 10\"","מערכת העיכול","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/haykol"],
  // 			["2020","\"בה\"\"ד 10\"","מערכת ההובלה","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/hovala"],
  // 			["2020","\"בה\"\"ד 10\"","מערכת העצבים","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/nerve-system"],
  // 			["2020","\"בה\"\"ד 10\"","מערכות עור, שתן, שלד ושריר","תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/sheten-oor-sheled"],
  // 			["2020","\"בה\"\"ד 10\"",'סילגו"ז מבוגר',"תוצר לשאלות אמריקאיות בצורה חוויתית ומשחקית יותר, מבוסס על הקונספט של המשחק מי רוצה להיות מליונר","מי רוצה להיות מליונר","https://mador-till-prod.github.io/BHD-10-Medics/silgoz-children"],
  // 			["2020","\"בה\"\"ד 10\"","קינימטיקה",'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה','תוצר ייעודי',"https://mador-till-prod.github.io/BHD-10-Medics/kinematics"],
  // 			["2020","\"בה\"\"ד 10\"","קינימטיקה תרגול","חלק מתרגל מתוך התוצר השלם של קינימטיקה","תוצר ייעודי","https://mador-till-prod.github.io/BHD-10-Medics/Kinematics-exer"],
  // 			["2020","\"בה\"\"ד 10\"","מצבים דחופים בשגרה","תוצר המכיל סימולציות של חופל ומטופל שמתרגל את החניכים על אופן טיפול, ושאילת שאלות נכונה.","תוצר ייעודי","https://mador-till-prod.github.io/BHD-10-Medics/Matzavim-Dhufim-Bashigra"],
  // 			["2020","\"בה\"\"ד 10\"","בדיקות שגרה",'התוצר פותח עבור קורס חובשים בבה"ד 10. התוצר ייעודי ומשמש כתרגול ביניים בשיעור בין למידת החומר התיאורטי ותרגול מעשי עם המפקד','תוצר ייעודי',"https://mador-till-prod.github.io/BHD-10-Medics/Routine-testing"],
  // 			["2020","\"בה\"\"ד 10\"","סקרים מתקדמים",'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה',"https://mador-till-prod.github.io/BHD-10-Medics/skarim-mitkadmim"],
  // 			["2020","\"בה\"\"ד 10\"","סקרים מתקדמים תרגול","חלק מתרגל מתוך התוצר השלם של סקרים מתקדמים (עליו התבסס התוצר של טופסולוגיה)","תוצר ייעודי","https://mador-till-prod.github.io/BHD-10-Medics/Skarim-mitkadmim-exer"],
  // 			["2020","\"בה\"\"ד 10\"","עקרונות הטיפול התרופתי","תוצר המנגיש מושגים ברפואים ב-4 דרכי למידה שונות לבחירת המשתמש.","מושגון","https://mador-till-prod.github.io/BHD-10-Medics/The-principles-of-drug"],
  // 			["2020","\"בה\"\"ד 10\"","CDE",'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה','תוצר ייעודי',"\"https://mador-till-prod.github.io/BHD-10-Medics/cde/"],
  // 			["2020","\"בה\"\"ד 10\"","CDE תרגול","חלק מתרגל מתוך התוצר השלם של CDE","תוצר ייעודי","https://mador-till-prod.github.io/BHD-10-Medics/cde-exer"],
  // 			["2020","\"בה\"\"ד 10\"","מחלות כרוניות",'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה',"תוצר ייעודי","https://mador-till-prod.github.io/BHD-10-Medics/chronic-diseases"],
  // 			["2020","\"בה\"\"ד 10\"","SAB",'תוצר ייעודי עבור קורס חובשים בבה"ד 10. התוצר מקנה ידע ומתרגל ועבור כחלק משיטת למידה של כיתה הפוכה',"תוצר ייעודי","https://mador-till-prod.github.io/BHD-10-Medics/sab"],
  // 			["2020","\"בה\"\"ד 10\"","SAB תרגול","חלק מתרגל מתוך התוצר השלם של SAB","תוצר ייעודי","https://mador-till-prod.github.io/BHD-10-Medics/sab-exer"],
  // 			["2021","\"בה\"\"ד 10\"","תקן 15","תוצר מנגיש ידע ומתרגל רשימה של מעל 100 תרופות שרשאי חובש לנפק יחד עם ההתוויות שעליו להגיד.","תוצר ייעודי","https://mador-till-prod.github.io/teken15/code/"],
  // 			["2021","\"בה\"\"ד 10\"","סייעות שיניים- מושגים","תוצר שמנגיש מושגים במגוון נושאים במספר דרכי למידה לבחירת המשתמש.","מושגון","https://mador-till-prod.github.io/dental-assistants/"],
  // 		],
  // 		[
  // 			["2022","\"בה\"\"ד 11\"","חוסן למפקד","","כרטיסיות","https://mador-till-prod.github.io/hosen_lamefaked_bhd11/code/"],
  // 			["2022","\"בה\"\"ד 11\"","זכויות החייל","","כרטיסיות","https://mador-till-prod.github.io/lomdat_zchout_hachayal_bhd_11/code/"],
  // 		],
  // 		[
  // 			["2021","\"בה\"\"ד חינוך\"","לומדת מטרות",'תוצר ייעודי עבור קורס הו"ד בבה"ד חינוך. התוצר מקנה ידע ומתרגל בצורה משחקית כתיבה נכונה של מטרות שיעור.',"תוצר ייעודי","https://mador-till-prod.github.io/targets/main.html"],
  // 		],
  // 		[
  // 			["2021","\"בה\"\"ד 13\"","שאלות לסיכום קורס בילוש","תוצר המנגיש שאלות לסיכום הקורס בצורה חוויתית ומשחקית, בטלפוני החניכים.","המירוץ לבסיס","https://mador-till-prod.github.io/race-bhd13-detective-course/main.html"],
  // 			["2021","\"בה\"\"ד 13\"","עגה עבריינית","תוצר המנגיש ביטויים ומילים הנהוגות אצל עבריינים למדריכי כליאה כדי שיכירו ויזהו אותם במהלך שירותם.","כרטיסיות","https://mador-till-prod.github.io/agaAvarianit/code/"],
  // 			["2021","\"בה\"\"ד 13\"","זכויות וחובות הכלוא","תוצר שנגיש ידע על כל זכויות וחובות של כלואים. מיועד לכלואים ולמדריכי הכליאה","כרטיסיות","https://mador-till-prod.github.io/prisoner-rights-bhd13/code/"],
  // 			["2021","\"בה\"\"ד 13\"","מבנה חמץ",'תוצר שפותח בשיתוף פעולה עם ההשלמה של בה"ד 13, תוצר המסביר על מבנה חמ"ץ תוכן עליו נבחנים ביום הראשון של ההשלמה.',"כרטיסיות","https://mador-till-prod.github.io/bhd13-hanatz/code/"],
  // 		],
  // 		[
  // 			["2021","\"בה\"\"ד 20\"","סימנים מוסכמים","תוצר אשר מקנה ידע ומתרגל. המותאם לטלפונים ניידים. בתוצר משולבים סרטונים וגיפים ליצירת המחשה ויזואלית מלאה של הסימנים.","תוצר ייעודי","https://mador-till-prod.github.io/agreedSigns20/"],
  // ["2021","\"בה\"\"ד 20\"","בטיחות",'לומדה שמשלבת סרטונים שפותחו בבה"ד 20 ושאלות. התוצר הוא כלל צהלי ומנגיש מידע בטיחותי',"כרטיסיות","https://mador-till-prod.github.io/lomda-safety-general/code/index.html"],
  // 		],
  // 	]

  // const yalla = [0,1,2,3,4,5,6,7]
  // for (let d = 0; d < arr.length; d++) {
  // 	for (let i = 0; i < arr[d].length; i++) {
  // 		try1[d].bahadArr.push({})
  // 		try1[d].bahadArr[i].year = arr[d][i][0]
  // 		try1[d].bahadArr[i].title = arr[d][i][2]
  // 		try1[d].bahadArr[i].description = arr[d][i][3]
  // 		try1[d].bahadArr[i].type = arr[d][i][4]
  // 		try1[d].bahadArr[i].link = arr[d][i][5]
  // 	}
  // }

  return (
    <div className="media page training">
      <Header header="עולם ההדרכה" text="למידה בעזרת לומדות מתקדמות ומהנות" />
      <navigatable className="trainingNavigator">
        <div className="content">
          <Navigator navigatorArr={navigatorArr} services="none" />
          <div className="textArea">
            {trainingArr.map((el, i) => {
              return (
                <TrainingCardList
                  trainingObj={el}
                  openSideBarComponent={openSideBarComponent}
                  chooseGame={chooseGame}
                  key={i}
                />
              );
            })}
            {/* {
							mediaArr.map((el, i) => {
								return (
									<>

										<GalleryDiv
											header={el.header}
											id={el.id}
											icon={el.icon}
											description={el.description}
											src={el.src}
											titles={el.titles}
											imgArr={el.imgArr}
											key={i}
										/>
										<Link
											to={"#"}
											className="flex-end"
											icon="icon-phone-black.svg"
											src={el.src}
											onClick={() => {
												openSideBarComponent("game");
												chooseGame(el.src);
											}}
										>
											הפעלה
										</Link>
									</>
								)
							})
						} */}
          </div>
        </div>
      </navigatable>
      <Sidebar
        openAppointmentSideBar={openAppointmentSideBar}
        setOpenAppointmentSideBar={setOpenAppointmentSideBar}
        openHospitalSideBar={openHospitalSideBar}
        setOpenHospitalSideBar={setOpenHospitalSideBar}
        closeSideBarComponent={closeSideBarComponent}
        openGameSideBar={openGameSideBar}
        game={game}
      />
    </div>
  );
};

export default Training;
