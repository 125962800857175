import SmallHeader from "../repeat/SmallHeader";
import GallerySwiper from "../repeat/gallerySwiper/GallerySwiper";
import { isBrowser } from "react-device-detect";

const GalleryDiv = ({ id, icon, displayname, header, imgArr }) => {
  return (
    <div id={id} icon={icon} displayname={displayname}>
      <SmallHeader title={header} />
      <GallerySwiper
        imgArr={imgArr}
        slidesPerView={isBrowser ? 2.5 : 1}
        className="mySwiper bigGallery"
        media={true}
      />
    </div>
  );
};

export default GalleryDiv;
