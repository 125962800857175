import React from "react";
import { Link } from "react-router-dom";

const VandorDetailsSquare = ({
  vandor_details_img,
  title_p_text,
  title_h6_text,
  title_span_text,
  image_content_text,
  link,
}) => {
  return (
    <>
      <div className="list_box col-6">
        <Link to={"" + link + ""}>
          <div className="box_img">
            <img src={vandor_details_img} alt="vandor" />
            <div className="time_dt dark">
              <p>
                <strong>{image_content_text}</strong>
              </p>
            </div>
          </div>
        </Link>
        <div className="box_detail">
          <div className="product_title">
            <p>{title_p_text}</p>
            <h6>{title_h6_text}</h6>
          </div>
          <span>{title_span_text}</span>
        </div>
      </div>
    </>
  );
};

export default VandorDetailsSquare;
