import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles

const VandorsSquareList = (props) => {
  const PopularServicesListArr = [
    {
      image: "/images/marketPlace/shekemLogoS.png",
      title: "שקם כוורת",
      link: "#",
    },
    {
      image: "/images/marketPlace/vandors2.jfif",
      title: "קפה ג'ו",
      link: "#",
    },
    {
      image: "/images/marketPlace/vandors1.jpg",
      title: "פוקס הום",
      link: "#",
    },
    {
      image: "/images/marketPlace/vandors2.jfif",
      title: "פוקס הום",
      link: "#",
    },
    {
      image: "/images/marketPlace/vandors1.jpg",
      title: "קפה ג'ו",
      link: "#",
    },
    {
      image: "/images/marketPlace/vandors2.jfif",
      title: "פוקס הום",
      link: "#",
    },
  ];

  const slides = PopularServicesListArr.map((el, index) => (
    <Link
      to={"#"}
      key={index}
      onClick={() => {
        props.openSideBarComponent("vandordetails");
      }}
    >
      <div className="slide_box">
        <div className="box_img">
          <img src={el.image} alt="vandors" />
        </div>
        <h6>{el.title}</h6>
      </div>
    </Link>
  ));

  return (
    <>
      <Swiper spaceBetween={5} slidesPerView={3} className="vandors_slider">
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default VandorsSquareList;
