import React from "react";
import { Link } from "react-router-dom";

const FeaturesSquare = (props) => {
  return (
    <Link
      className="hospitalDepartmentsBoxImg"
      to={props.link}
      // onClick={() => props.openSideBarComponent("marketplace")}
    >
      <img src={props.img} alt="hospital" />
      <div className="hospitalDepartmentsBoxTitle">{props.title}</div>
    </Link>
  );
};

export default FeaturesSquare;
