import MealTime from "./MealTime";

const MealDays = ({ days, time }) => {
  return (
    <div>
      <div className="emphasis subtitle">{days}</div>
      {time.map((el, i) => {
        return (
          <MealTime img={el.img} text={el.text} hours={el.hours} key={i} />
        );
      })}
    </div>
  );
};

export default MealDays;
