import "swiper/css";
import "swiper/css/pagination";
import "./App.css";
import { BrowserRouter } from "react-router-dom";

import WebPage from "./components/web/WebPage";
// import ProductDetails from "./components/web/marketPlace/productDetails/ProductDetails"
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <WebPage />
      </BrowserRouter>
    </>
  );
}

export default App;
