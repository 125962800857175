import "./WebPage.css";
import { Routes, Route } from "react-router-dom";
import Nav from "./nav/Nav";
import Negev from "./negev/Negev";
import Footer from "./footer/Footer";
import Home from "./home/Home";
import TrainingBase from "./trainingBase/TrainingBase";
import TrainingBases from "./trainingBase/TrainingBases";
import Transport from "./transport/Transport";
import Dining from "./dining/Dining";
import Services from "./services/Services";
import Equipment from "./equipment/Equipment";
import Healthcare from "./healthcare/Healthcare";
import Media from "./media/Media";
import Training from "./training/Training";
import Tkbel from "./tkbel/Tkbel";
import Department from "./department/Department";
import RecreationAndFitness from "./recreationAndFitness/RecreationAndFitness";
import Scrollup from "./Scrollup";
import Blog from "./blogs/Blog";
import News from "./news/News";

const WebPage = () => {
  return (
    <div className="webPage main">
      <Nav />
      <div className="scrollable">
        <Scrollup>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="trainingbase/:divisionId" element={<TrainingBase />} />
            <Route path="trainingbases" element={<TrainingBases />} />
            <Route
              exact
              path="trainingbase/:divisionId/menu/:menuId/content/:contentId"
              element={<Blog />}
            />
            <Route path="transport" element={<Transport />} />
            <Route path="dining" element={<Dining />} />
            <Route path="services" element={<Services />} />
            <Route path="equipment" element={<Equipment />} />
            <Route path="healthcare" element={<Healthcare />} />
            <Route path="media" element={<Media />} />
            <Route path="training" element={<Training />} />
            <Route path="teaching" element={<Tkbel />} />
            <Route path="negev" element={<Negev />} />
            <Route
              path="recreation-and-fitness"
              element={<RecreationAndFitness />}
            />
            <Route path="department/1" element={<Department id={1} />} />
            <Route path="department/2" element={<Department id={2} />} />
            <Route path="department/3" element={<Department id={3} />} />
            <Route path="news" element={<News />} />
          </Routes>
        </Scrollup>
      </div>
      <Footer />
    </div>
  );
};

export default WebPage;
