import React from "react";
import { Container } from "react-bootstrap";
import Banner from "./banner/Banner";
import DepartmentsList from "./departmentsList/DepartmentsList";
import ServicesSqaureList from "./servicesSqaure/ServicesSqaureList";

import "./Negev.css";

const Negev = (props) => {
  return (
    <>
      <div className="hospitalWebPages rtl">
        <Container>
          <Banner id={props.id} />
          <DepartmentsList />
          <ServicesSqaureList />
        </Container>
      </div>
    </>
  );
};

export default Negev;
